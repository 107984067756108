import { submitAddNewPaymentCard } from "#Graphql/mutate";
import { fetchClientPaymentMethod } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import dropin from "braintree-web-drop-in";
import React, { PureComponent, createRef } from "react";
import { withToast } from "#hoc/withToast";
import { Alert, Spinner } from "reactstrap";

class PaymentDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dropInInstance: null,
      clientPaymentToken: null,
    };
    this.dropinContainer = createRef();
  }

  async componentDidMount() {
    await this.getClientPaymentMethod();
  }

  async componentDidUpdate(prevProps, prevState) {
    // Initialize Braintree Drop-in when token is received
    if (
      this.state.clientPaymentToken &&
      !this.state.dropInInstance &&
      (!prevState.clientPaymentToken ||
        prevState.clientPaymentToken !== this.state.clientPaymentToken)
    ) {
      await this.initializeBraintreeDropin();
    }
  }

  componentWillUnmount() {
    // Clean up the Drop-in instance when component unmounts
    if (this.state.dropInInstance) {
      this.state.dropInInstance.teardown();
    }
  }

  initializeBraintreeDropin = async () => {
    try {
      const options = {
        authorization: this.state.clientPaymentToken,
        container: this.dropinContainer.current,
        paymentOptionPriority: [
          "card",
          "paypal",
          "paypalCredit",
          // "venmo",
          // "applePay",
          // "googlePay",
        ],
        paypal: { flow: "vault", amount: "", currency: "USD" },
        paypalCredit: { flow: "vault", amount: "", currency: "USD" },
        vaultManager: true,
        preselectVaultedPaymentMethod: true,
      };

      const instance = await dropin.create(options);

      // Set up event listeners
      instance.on("paymentMethodRequestable", this.onPaymentMethodRequestable);
      instance.on(
        "noPaymentMethodRequestable",
        this.noPaymentMethodRequestable,
      );
      instance.on("paymentOptionSelected", this.onPaymentOptionSelected);

      this.setState({ dropInInstance: instance });

      // Initialize button state
      const submitButton = document.querySelector("#submitButton");
      if (submitButton) {
        submitButton.setAttribute("disabled", true);
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  getClientPaymentMethod = async () => {
    try {
      this.setState({ isLoading: true });
      const { getClientPaymentMethod } = await fetchClientPaymentMethod();
      this.setState({ isLoading: false });
      if (getClientPaymentMethod) {
        this.setState({
          clientPaymentToken: getClientPaymentMethod,
        });
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  addPaymentCard = async () => {
    const { dropInInstance } = this.state;
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );

    try {
      const { nonce } = await dropInInstance.requestPaymentMethod();
      const submitButton = document.querySelector("#submitButton");
      submitButton.setAttribute("disabled", true);

      try {
        const { addNewPaymentCard } = await submitAddNewPaymentCard({
          userId: user.userId,
          paymentMethodNonce: nonce,
        });
        if (addNewPaymentCard && addNewPaymentCard.code === 200) {
          this.showSuccessMessage(addNewPaymentCard.message);
          this.getClientPaymentMethod();
          this.forceUpdate();
        } else {
          this.errorHandler(response);
        }
      } catch (response) {
        this.errorHandler(response);
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  noPaymentMethodRequestable = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.setAttribute("disabled", true);
  };
  onPaymentMethodRequestable = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.removeAttribute("disabled", true);
    if (event.paymentMethodIsSelected) {
      submitButton.setAttribute("disabled", true);
    }
  };

  onPaymentOptionSelected = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.setAttribute("disabled", true);
  };

  brainTreeDropInInstance = (dropInInstance) => {
    this.setState({ dropInInstance }, () => {
      const submitButton = document.querySelector("#submitButton");
      submitButton.setAttribute("disabled", true);
    });
  };

  render() {
    const { clientPaymentToken, isLoading, dropInInstance } = this.state;

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (clientPaymentToken) {
      return (
        <>
          <Alert color="warning">
            <i className="fa fa-warning" /> Deleting a payment method may cancel
            your active subscriptions!
          </Alert>

          {/* Container for Braintree Drop-in UI */}
          <div ref={this.dropinContainer}></div>

          {dropInInstance && (
            <div className="text-center">
              <button
                id="submitButton"
                onClick={this.addPaymentCard}
                className="mt-4 mb-4 btn btn-primary btn-md"
              >
                Save
              </button>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="mt-4 mb-4 p-3">
          <div className="loading-spinner">
            <Spinner type="grow" />
          </div>
        </div>
      );
    }
  }
}

export default withToast(withRouter(PaymentDetails));
