import InputForm, { SubmitButton, TextareaField } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Button, Spinner } from "reactstrap";

class TicketDescription extends PureComponent {
  state = {
    isLoading: false,
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "description") {
      this.setState({ isOpen: false });
    }
  }

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  updateTicketDescriptionSubmit = async ({ formFields }) => {
    const { ticketDetails, onUpdate = () => {} } = this.props;
    this.setState({ isLoading: true });
    try {
      const { description } = formFields;
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input: { description },
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails, handleSubmit } = this.props;
    const { isOpen, isLoading } = this.state;
    const canEdit = ticketDetails?.state !== TICKET_STATES.closed;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isClient && canEdit) {
      return (
        <div className="editable-block">
          {!isOpen && !ticketDetails.description ? (
            <Button
              color="link"
              size="sm"
              className="ps-0 font-12"
              onClick={() => this.handleEditFields("description")}
            >
              Add Description
            </Button>
          ) : null}

          {!isOpen && ticketDetails.description ? (
            <div
              className="message-block hover-edit"
              onClick={() => this.handleEditFields("description")}
              title="Click here to edit"
            >
              {ticketDetails.description}
              <i className="fa fa-pencil" />
            </div>
          ) : null}

          {isOpen ? (
            <div className="editable-field" style={{ width: "100%" }}>
              <InputForm
                initialValues={{
                  description: ticketDetails?.description,
                }}
                onSubmit={this.updateTicketDescriptionSubmit}
              >
                <TextareaField name="description" />
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("description")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="message-block not-editable-block">
          {ticketDetails.description}
        </div>
      );
    }
  }
}

export default withToast(TicketDescription);
