import { actionsMap, buildMap } from "#Components/AutoDeployment";
import Collapsable from "#Components/Collapsable";
import {
  agentDuplicateAction,
  agentDeleteAction,
  agentDisableSSOAction,
  agentEnableSSOAction,
  agentLaunchAction,
  agentPauseAction,
  agentResumeAction,
  agentStatusAction,
  agentTokenAction,
  agentUpgradeAction,
  pauseAction,
  resumeAction,
  tokenAction,
  upgradeAction,
} from "#Constants/Endpoints";
import { downloadContentAsFile, getRemainingTime } from "#hoc/util";
import { Badge, Button, Col, Row, Table } from "reactstrap";

const AgentActions = (props) => {
  const { actionButtonsDisabled = false, applyAction = (x) => {} } = props;
  return (
    <>
      <Row className="mt-2">
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["status"]}
            onClick={() => applyAction({ ticketAction: agentStatusAction })}
          >
            Status
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["resume"]}
            onClick={() => applyAction({ ticketAction: agentResumeAction })}
          >
            Resume
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["pause"]}
            onClick={() => applyAction({ ticketAction: agentPauseAction })}
          >
            Pause
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["delete"]}
            onClick={() => applyAction({ ticketAction: agentDeleteAction })}
          >
            Delete
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["launch"]}
            onClick={() => applyAction({ ticketAction: agentLaunchAction })}
          >
            Launch
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["launch"]}
            onClick={() =>
              applyAction({
                ticketAction: agentDuplicateAction,
              })
            }
          >
            Duplicate
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["upgrade"]}
            onClick={() => applyAction({ ticketAction: agentUpgradeAction })}
          >
            Upgrade
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["launch"]}
            onClick={() => applyAction({ ticketAction: agentEnableSSOAction })}
          >
            Enable SSO
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["launch"]}
            onClick={() => applyAction({ ticketAction: agentDisableSSOAction })}
          >
            Disable SSO
          </Button>
        </Col>
      </Row>
    </>
  );
  return null;
};

const ExemptActions = (props) => {
  const {
    ticketDetails,
    actionButtonsDisabled = false,
    applyAction = (x) => {},
  } = props;
  const { deployment = {} } = ticketDetails;
  const { deploymentAction = "" } = deployment;

  return (
    <Row className="m-1 d-flex justify-content-center">
      <Col className="p-1">
        <Button
          block
          disabled={
            actionButtonsDisabled || (deploymentAction + "").match(/^delete$/gi)
          }
          className="font-12"
          size="sm"
          color={actionsMap["pause"]}
          onClick={() => applyAction({ ticketAction: pauseAction })}
        >
          Pause
        </Button>
      </Col>
      <Col className="p-1">
        <Button
          block
          disabled={actionButtonsDisabled}
          className="font-12"
          size="sm"
          color={actionsMap["resume"]}
          onClick={() => applyAction({ ticketAction: resumeAction })}
        >
          {(deploymentAction + "").match(/^delete$/gi) ? "Reinstall" : "Resume"}
        </Button>
      </Col>
      <Col className="p-1">
        <Button
          block
          disabled={
            actionButtonsDisabled || (deploymentAction + "").match(/^delete$/gi)
          }
          className="font-12"
          size="sm"
          color={actionsMap["upgrade"]}
          onClick={() => applyAction({ ticketAction: upgradeAction })}
        >
          Upgrade
        </Button>
      </Col>
    </Row>
  );
};

const PrivilegedActions = (props) => {
  const { user, org, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  if (userRoleInOrg.isAgent || org.exempt) {
    const labelWidth = 150;
    const {
      ticketDetails,
      userToken,
      selfServiceBuildStatus = {},
      applyAction = (x) => {},
      actionButtonsDisabled = false,
    } = props;
    const { buildStatus = "" } = selfServiceBuildStatus;
    const { deployment = {}, currentAssignee = {} } = ticketDetails;
    const { deploymentAction = "" } = deployment;
    const actionColor = actionsMap[deploymentAction?.toLowerCase()];
    const actionLabel = deploymentAction?.split("_").join(" ");
    const buildColor = buildMap[buildStatus?.toLowerCase()];
    const agentTokenStatus = getRemainingTime(
      userToken?.tokenObj?.status?.expirationTimestamp,
    );
    const isAssignee = currentAssignee.id === user.id;
    const tokenActionDisabled =
      actionButtonsDisabled || (deploymentAction + "").match(/^delete$/gi);
    return (
      <Collapsable label="Privileged Actions" small red>
        <div className="service-actions-wrapper warning-box">
          <Table size="sm" borderless>
            <tbody>
              {buildStatus && (
                <>
                  <tr>
                    <th width={labelWidth}>Namespace:</th>
                    <td>{`org-${deployment.orgId}-tkt-${ticketDetails.id}`}</td>
                  </tr>
                  {userRoleInOrg.isAgent ? (
                    <tr>
                      <th width={labelWidth}>Subscription Id:</th>
                      <td>{deployment.subscriptionId || ""}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <th width={labelWidth}>Last Action:</th>
                    <td>
                      <Badge color={actionColor}>
                        {(actionLabel + "").toUpperCase()}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th width={labelWidth}>Action Status:</th>
                    <td>
                      <Badge color={buildColor}>
                        {(buildStatus + "").toUpperCase()}
                      </Badge>
                    </td>
                  </tr>
                  {(userRoleInOrg.isAgent && isAssignee) || org.exempt ? (
                    <tr>
                      <th width={labelWidth}>Token Status:</th>
                      <td>
                        {agentTokenStatus.remainingSeconds > 0 ? (
                          <>
                            <Badge
                              disabled
                              className={
                                tokenActionDisabled
                                  ? "white"
                                  : "inline-link white"
                              }
                              color={
                                tokenActionDisabled ? "secondary" : "primary"
                              }
                              onClick={() => {
                                !tokenActionDisabled &&
                                  downloadContentAsFile({
                                    content: userToken.kubeConfigObj.yaml,
                                    fileName: userToken.kubeConfigObj.file,
                                  });
                              }}
                            >
                              DOWNLOAD
                            </Badge>{" "}
                            Expires in{" "}
                            {agentTokenStatus.remainingHours > 0 ? (
                              <>{agentTokenStatus.remainingHours} hour(s)</>
                            ) : (
                              <>
                                {agentTokenStatus.remainingSeconds} seconds(s)
                              </>
                            )}
                          </>
                        ) : (
                          <Badge
                            disabled={
                              actionButtonsDisabled ||
                              (deploymentAction + "").match(/^delete$/gi)
                            }
                            className={
                              tokenActionDisabled
                                ? "white"
                                : "inline-link white"
                            }
                            color={
                              tokenActionDisabled ? "secondary" : "primary"
                            }
                            onClick={() => {
                              !tokenActionDisabled &&
                                applyAction({
                                  ticketAction: userRoleInOrg.isAgent
                                    ? agentTokenAction
                                    : tokenAction,
                                  actionConfig: { userId: user.id },
                                });
                            }}
                          >
                            Generate Token
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ) : null}
                </>
              )}
              <tr>
                <th width={labelWidth}>Available Actions:</th>
                <td>
                  {userRoleInOrg.isAgent ? (
                    <AgentActions {...props} />
                  ) : (
                    <ExemptActions {...props} />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Collapsable>
    );
  }
  return null;
};
export default PrivilegedActions;
