import Collapsable from "#Components/Collapsable";
import { SolutionItem } from "#Components/SolutionItem";
import TabNav from "#Components/TabNav";
import withRouter from "#hoc/withRouter";
import React, { PureComponent } from "react";

import { withToast } from "#hoc/withToast";
import { Button, Input, InputGroup } from "reactstrap";

class DeploymentsList extends PureComponent {
  state = {
    filterBy: "",
  };

  handleSearch = (evt) => {
    this.setState({ filterBy: evt.target.value });
  };

  handleClear = () => {
    this.setState({ filterBy: "" });
  };

  renderDeployments(showApps) {
    const { filterBy } = this.state;
    const {
      selectedCapacity = "x-small",
      deploymentsList = [],
      onSelectDeployment = (x) => {},
      onSelectCapacity = (x) => {},
    } = this.props;
    const apps = deploymentsList.filter(
      ({ serviceAttributes }) => !serviceAttributes.suiteApps,
    );
    const appSuites = deploymentsList.filter(
      ({ serviceAttributes }) => !!serviceAttributes.suiteApps,
    );
    const selfServiceMap = {};
    const re = new RegExp(filterBy, "gi");
    (showApps ? apps : appSuites).forEach((solutionItem) => {
      const { serviceName, serviceAttributes } = solutionItem;
      const { serviceType, serviceTypeLabel } = serviceAttributes;
      if (
        serviceName?.match(re) ||
        JSON.stringify(serviceAttributes).match(re)
      ) {
        selfServiceMap[serviceType] = selfServiceMap[serviceType] || {};
        const selfServiceObj = selfServiceMap[serviceType];
        selfServiceObj["label"] = serviceTypeLabel;
        selfServiceObj["list"] = selfServiceObj["list"] || [];
        selfServiceObj["list"].push({ ...solutionItem });
      }
    });
    return (
      <div>
        <InputGroup size="sm" className="p-3">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            aria-label="Search Input"
            value={filterBy}
            onChange={this.handleSearch}
          />
          <Button onClick={this.handleClear} color="primary">
            X
          </Button>
        </InputGroup>
        {Object.keys(selfServiceMap)
          .sort()
          .map((serviceType, typeIdx) => (
            <Collapsable
              key={typeIdx}
              label={selfServiceMap[serviceType]["label"]}
              collapsed={false}
            >
              {selfServiceMap[serviceType]["list"]
                .sort(({ serviceAttributes: a }, { serviceAttributes: b }) => {
                  const pA = a?.planId?.padStart(10, "0");
                  const pB = b?.planId?.padStart(10, "0");
                  return pA < pB ? -1 : pA > pB ? 1 : 0;
                })
                .map((solutionItem, idx) => (
                  <SolutionItem
                    key={idx}
                    solutionItem={solutionItem}
                    selectedCapacity={selectedCapacity}
                    onClick={() => {
                      onSelectDeployment({
                        serviceType,
                        ...solutionItem,
                      });
                    }}
                    onCapacitySelect={(capacity) => {
                      onSelectCapacity(capacity);
                    }}
                    collapsed={true}
                  />
                ))}
            </Collapsable>
          ))}
      </div>
    );
  }

  render() {
    return (
      <TabNav
        tabs={[
          {
            tabId: "/apps",
            tabLabel: "Apps",
            tabComponent: <>{this.renderDeployments(true)}</>,
          },
          {
            tabId: "/bundles",
            tabLabel: "Bundles",
            tabComponent: <>{this.renderDeployments()}</>,
          },
        ]}
        width="large"
      />
    );
  }
}

export default withToast(withRouter(DeploymentsList));
