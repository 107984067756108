import ErrorBoundary from "#Components/ErrorBoundary";
import InputForm, {
  SubmitButton,
  TextareaField,
  TextField,
} from "#Components/InputForm";
import { VideoPreview } from "#Components/VideoPreview";
import { submitSendHelpQuestion } from "#Graphql/mutate";
import { validate } from "#pages/GetHelp/helper";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

class GetHelp extends PureComponent {
  state = {
    isLoading: false,
  };
  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  handleQuestionSubmit = async ({ formFields: values }) => {
    const { question, description } = values;
    try {
      this.setState({ isLoading: true });
      const { sendHelpQuestion } = await submitSendHelpQuestion({
        question,
        description,
      });
      if (sendHelpQuestion && sendHelpQuestion.code === 200) {
        this.showSuccessMessage(sendHelpQuestion.message);
      } else {
        this.errorHandler(sendHelpQuestion);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    return (
      <Card className="content-wrapper">
        <ErrorBoundary error="bg-error">
          <h6 className="mt-4 mb-4 page-title">Educational Videos</h6>
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <VideoPreview
                title="Cloning an Application or Bundle"
                linkId="4hjCGwy7Odo"
                description="Here's how to Clone an Application or Bundle?"
              />
            </Col>
            <Col md={6}>
              <VideoPreview
                title="AI Agent"
                linkId="PNRFPbWJSMc"
                description="You can now interact with our AI assistant for instant support with any installed app. Let's see how?"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <VideoPreview
                title="Authorized Organizations"
                linkId="5aAozfBLKUE"
                description="Here's how to add an organization to your account, add users to the new organization, and authorize a user from another organization to have access to your organization."
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="get-help">
                <CardBody className="get-help-wrapper">
                  <h6 className="mb-4 page-title">Contact Us</h6>
                  <h6 className="mt-2 mb-4">
                    Still haven't found what you're looking for? Please complete
                    the form below to contact our support team.
                  </h6>
                  <InputForm
                    validator={validate}
                    onSubmit={this.handleQuestionSubmit}
                  >
                    <div className="submit-question">
                      <FormGroup>
                        <Label>Subject *</Label>
                        <TextField name="question" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Message *</Label>
                        <TextareaField name="description" />
                      </FormGroup>
                      <FormGroup className="text-end">
                        <SubmitButton size="sm" color="primary">
                          Submit Question
                        </SubmitButton>
                      </FormGroup>
                    </div>
                  </InputForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </Card>
    );
  }
}

export default withToast(GetHelp);
