import { OrgBrand, OrgSelector } from "#Components/AuthorizedOrganizations.jsx";
import { getUserIcon, getUserType, switchOrganization } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import React, { PureComponent, useState } from "react";
import { withToast } from "#hoc/withToast";
import { Link } from "react-router-dom";
import { Badge, Collapse, Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";

const NavBrand = ({ onSwitchOrg = (x) => {} }) => {
  const { user, org, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="bg-light">
      <div className="d-flex justify-content-center pb-1 pt-3">
        <Badge color="primary">{getUserType(userRoleInOrg)}</Badge>
      </div>
      <NavbarBrand
        title={org.name}
        tag="div"
        className="d-flex justify-content-center m-0 p-0 mb-3 w-100"
      >
        {userRoleInOrg.isClient &&
        user.authorizedOrgs &&
        user.authorizedOrgs.length ? (
          <OrgSelector
            orgOptions={[user.organization, ...user.authorizedOrgs]}
            selectedOrgId={org.id}
            onSwitchOrg={onSwitchOrg}
          />
        ) : (
          <OrgBrand
            org={org}
            user={user}
            className="brown m-3"
            onClick={toggle}
          />
        )}
      </NavbarBrand>
    </div>
  );
};

class Sidebar extends PureComponent {
  state = {
    selectedNav: "",
  };

  componentDidMount() {
    this.setState({ selectedNav: this.props.location.pathname });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ selectedNav: this.props.location.pathname });
    }
  }

  showSuccessMessage = (message) => {
    this.props.alert.success(message);
  };

  errorHandler = (error) => {
    this.props.alert.error(error.message || "Unknown Error");
  };

  renderNavItem({
    tabId,
    paths = [],
    tabLabel,
    tabIcon,
    newIndicator = false,
  }) {
    const { selectedNav = "" } = this.state;
    !paths.length && paths.push(new RegExp(`^/${tabId}`, "gi"));
    const selectedTab = paths.some((path) => selectedNav.match(path));
    const classNames = ["p-3", "d-flex", "align-items-center", "nav-link"];
    selectedTab && classNames.push("active");
    return (
      <NavItem>
        <Link to={`/${tabId}`} className={classNames.join(" ")}>
          <i
            className={`nav-icon ${tabIcon}`}
            style={{ fontSize: "1.25rem" }}
          />
          <span className="ms-3 me-3 text-uppercase">{tabLabel}</span>
          {newIndicator ? (
            <Badge color="primary" pill>
              New!
            </Badge>
          ) : null}
        </Link>
      </NavItem>
    );
  }

  onSwitchOrg = async (authOrg) => {
    try {
      await switchOrganization(authOrg);
    } catch (err) {
      this.errorHandler(err);
    }
  };

  renderNavMenu = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { showSidebar } = this.props;
    if (userRoleInOrg.isClient) {
      return (
        <Collapse horizontal isOpen={showSidebar} className="sidebar">
          <NavBrand onSwitchOrg={this.onSwitchOrg} />
          <Nav navbar vertical className="nav-menu">
            {this.renderNavItem({
              tabId: "deployments",
              paths: [/^\/deployments/gi, /^\/deployment\//gi, /^\/$/gi],
              tabLabel: "My Apps",
              tabIcon: "fa fa-server",
            })}
            {this.renderNavItem({
              tabId: "tickets",
              paths: [/^\/tickets/gi, /^\/support/gi],
              tabLabel: "Support",
              tabIcon: "fa fa-ticket",
            })}
            {userRoleInOrg.isClientAdmin &&
              this.renderNavItem({
                tabId: "people",
                tabLabel: "Users",
                tabIcon: "fa fa-users",
              })}
            {this.renderNavItem({
              tabId: "profile",
              tabLabel: "Profile",
              tabIcon: getUserIcon(userRoleInOrg),
            })}
            {this.renderNavItem({
              tabId: "history",
              tabLabel: "History",
              tabIcon: "fa fa-history",
            })}
            {this.renderNavItem({
              tabId: "help",
              tabLabel: "Help",
              tabIcon: "fa fa-question-circle",
              newIndicator: true,
            })}
          </Nav>
        </Collapse>
      );
    }

    if (userRoleInOrg.isAgent) {
      return (
        <Collapse navbar horizontal isOpen={showSidebar} className="sidebar">
          <NavBrand />
          <Nav navbar vertical className="nav-menu">
            {this.renderNavItem({
              tabId: "tickets",
              paths: [
                /^\/tickets/gi,
                /^\/deployment/gi,
                /^\/support/gi,
                /^\/$/gi,
              ],
              tabLabel: "Tickets",
              tabIcon: "fa fa-ticket",
            })}
            {userRoleInOrg.isAgentAdmin &&
              this.renderNavItem({
                tabId: "clients",
                tabLabel: "Clients",
                tabIcon: "fa fa-people-group",
              })}
            {userRoleInOrg.isAgentAdmin &&
              this.renderNavItem({
                tabId: "people",
                tabLabel: "Agents",
                tabIcon: "fa fa-users-viewfinder",
              })}
            {this.renderNavItem({
              tabId: "profile",
              tabLabel: "Profile",
              tabIcon: getUserIcon(userRoleInOrg),
            })}
            {this.renderNavItem({
              tabId: "history",
              tabLabel: "History",
              tabIcon: "fa fa-history",
            })}
          </Nav>
        </Collapse>
      );
    }

    if (userRoleInOrg.isSuperAdmin || userRoleInOrg.isSalesAgent) {
      return (
        <Collapse navbar horizontal isOpen={showSidebar} className="sidebar">
          <NavBrand />
          <Nav navbar vertical className="nav-menu">
            {this.renderNavItem({
              tabId: "clients",
              paths: [/^\/clients/gi, /^\/$/gi],
              tabLabel: "Clients",
              tabIcon: "fa fa-people-group",
            })}
            {userRoleInOrg.isSuperAdmin
              ? this.renderNavItem({
                  tabId: "people",
                  tabLabel: "Agents",
                  tabIcon: "fa fa-users-viewfinder",
                })
              : null}
            {userRoleInOrg.isSuperAdmin
              ? this.renderNavItem({
                  tabId: "clusters",
                  tabLabel: "Clusters",
                  tabIcon: "fa fa-desktop",
                })
              : null}
            {this.renderNavItem({
              tabId: "profile",
              tabLabel: "Profile",
              tabIcon: getUserIcon(userRoleInOrg),
            })}
            {this.renderNavItem({
              tabId: "history",
              tabLabel: "History",
              tabIcon: "fa fa-history",
            })}
          </Nav>
        </Collapse>
      );
    }
  };
  render = () => {
    return (
      <Navbar className="p-0" container={false}>
        {this.renderNavMenu()}
      </Navbar>
    );
  };
}

export default withToast(withRouter(Sidebar));
