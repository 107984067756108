import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Account from "./pages/Account";
import App from "./pages/App";

import "./styles/index.scss";

// Configure toast globally
const toastConfig = {
  position: "bottom-left",
  autoClose: 10000, // 10 seconds
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
  style: {
    maxWidth: "400px",
  },
};

// Create a helper for formatting messages
export const formatToastMessage = (message) => {
  if (typeof message === "string") {
    message = [message];
  }

  return message.map((msg) => {
    if (msg && msg.match && msg.match(/^graphQL error:/gi)) {
      return msg.replace(/^graphQL error:/gi, "");
    }
    return msg;
  });
};

// Export toast functions for use throughout the app
export const showToast = {
  success: (message) => {
    const formattedMsg = formatToastMessage(message);
    formattedMsg.forEach((msg) => toast.success(msg, toastConfig));
  },
  error: (message) => {
    const formattedMsg = formatToastMessage(message);
    formattedMsg.forEach((msg) => toast.error(msg, toastConfig));
  },
  info: (message) => {
    const formattedMsg = formatToastMessage(message);
    formattedMsg.forEach((msg) => toast.info(msg, toastConfig));
  },
  warn: (message) => {
    const formattedMsg = formatToastMessage(message);
    formattedMsg.forEach((msg) => toast.warn(msg, toastConfig));
  },
};

const Main = () => {
  return (
    <section className="main-container">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Account />} />
          <Route path="/signup" element={<Account />}>
            <Route path="success" element={<Account />} />
          </Route>
          <Route path="/forgot-password" element={<Account />} />
          <Route path="/reset-password" element={<Account />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </section>
  );
};

createRoot(document.getElementById("root")).render(
  <>
    <Main />
    <div className="copyright">
      <div>
        Clouve © {new Date().getFullYear()}&nbsp;&bull;&nbsp;All rights
        reserved
        <br />
        <a href="https://www.clouve.com/privacy/" target="_blank">
          Terms of Service
        </a>
        &nbsp;&bull;&nbsp;
        <a href="https://www.clouve.com/terms/" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
    <ToastContainer
      position={toastConfig.position}
      autoClose={toastConfig.autoClose}
      hideProgressBar={toastConfig.hideProgressBar}
      newestOnTop={false}
      closeOnClick={toastConfig.closeOnClick}
      rtl={false}
      pauseOnFocusLoss
      draggable={toastConfig.draggable}
      pauseOnHover={toastConfig.pauseOnHover}
      theme={toastConfig.theme}
      style={toastConfig.style}
    />
  </>,
);
