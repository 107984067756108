import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Button, Spinner } from "reactstrap";

class TicketTitle extends PureComponent {
  state = {
    isLoading: false,
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "title") {
      this.setState({ isOpen: false });
    }
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);

    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  updateTicketTitleSubmit = async ({ formFields }) => {
    this.setState({ isProgress: true });
    const { ticketDetails, onUpdate = () => {} } = this.props;
    this.setState({ isLoading: true });
    try {
      const { title } = formFields;
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input: { title },
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { isOpen, isLoading } = this.state;
    const canEdit = ticketDetails?.state !== TICKET_STATES.closed;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isClient && canEdit) {
      return (
        <div className="editable-block">
          {isOpen ? (
            <div className="editable-field">
              <InputForm
                initialValues={{ title: ticketDetails?.title }}
                onSubmit={this.updateTicketTitleSubmit}
              >
                <TextField name="title" />
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("title")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : (
            <h5
              className="hover-edit"
              onClick={() => this.handleEditFields("title")}
              title="Click here to edit"
            >
              {ticketDetails.title}
              <i className="fa fa-pencil" />
            </h5>
          )}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">
          <h5>{ticketDetails.title}</h5>
        </div>
      );
    }
  }
}

export default withToast(TicketTitle);
