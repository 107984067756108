import { PasswordValidator } from "#Components/FormValidators";
import InputForm, { PasswordField, SubmitButton } from "#Components/InputForm";
import Logo from "#Components/Logo.jsx";
import { submitResetPassword } from "#Graphql/mutate";
import withRouter from "#hoc/withRouter";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Link } from "react-router-dom";

import { Alert, FormGroup, Label } from "reactstrap";

class ResetPassword extends PureComponent {
  state = {
    isLoading: false,
    showSuccessMessage: "",
  };

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      token: location.searchParams.get("token"),
    });
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message || "Unknown Error");
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false, showSuccessMessage: true });
    this.props.alert.success(message);
  };

  handleResetPassword = async ({ formFields: values }) => {
    const { token } = this.state;
    const { newPassword } = values;
    grecaptcha.enterprise.ready(async () => {
      this.setState({ isLoading: true });
      try {
        const captchaToken = await grecaptcha.enterprise.execute(
          VITE_GOOGLE_RECAPTCHA_ID,
          { action: "reset_password" },
        );
        const { resetPassword } = await submitResetPassword({
          token,
          newPassword,
          captchaToken,
        });
        if (resetPassword && resetPassword.code === 200) {
          this.showSuccessMessage(resetPassword.message);
        } else {
          this.errorHandler(resetPassword);
        }
      } catch (err) {
        this.errorHandler(err);
      }
    });
  };

  render() {
    const { token, showSuccessMessage, isLoading } = this.state;
    const classNames = ["reset-password", "form"];
    isLoading && classNames.push("spinner");

    if (!token) {
      return (
        <div className={classNames.join(" ")}>
          <FormGroup>
            <Logo />
            <h5 className="text-center m-4">Reset your password</h5>
            <Alert color="warning" className="m-4">
              Password Reset Token Not Provided.
            </Alert>
            <Link to={"/login"}>Login</Link>
          </FormGroup>
        </div>
      );
    }

    if (showSuccessMessage) {
      return (
        <div className={classNames.join(" ")}>
          <FormGroup>
            <Logo />
            <h5 className="text-center m-4">Reset your password</h5>
            <Alert color="success" className="m-4">
              Your password has been updated successfully!
            </Alert>
            <Link to={"/login"}>Login</Link>
          </FormGroup>
        </div>
      );
    }

    return (
      <div className={classNames.join(" ")}>
        <Logo />
        <h5 className="text-center m-4">Reset your password</h5>
        <InputForm
          validator={PasswordValidator(["newPassword", "confirmPassword"])}
          onSubmit={this.handleResetPassword}
        >
          <FormGroup id="password" className="position-relative">
            <Label for="newPassword">New Password</Label>
            <PasswordField
              id="newPassword"
              name="newPassword"
              showStrengthBar={true}
            />
          </FormGroup>

          <FormGroup className="position-relative">
            <Label for="confirmPassword">Confirm Password</Label>
            <PasswordField id="confirmPassword" name="confirmPassword" />
          </FormGroup>

          <FormGroup className="mb-3">
            <SubmitButton size="lg" color="primary" block={true}>
              Set New Password
            </SubmitButton>
          </FormGroup>
          <FormGroup className="mb-3 text-center">
            <Link to={"/login"}>Login</Link>
          </FormGroup>
        </InputForm>
      </div>
    );
  }
}

export default withToast(withRouter(ResetPassword));
