import { submitResendVerificationEmail } from "#Graphql/mutate";
import { getUserIcon } from "#hoc/util";

import ResetPassword from "#pages/Profile/MyProfile/ResetPassword";
import React, { useState } from "react";
import { withToast } from "#hoc/withToast";
import { Alert, Button, Col, Input, Label, Row, Spinner } from "reactstrap";

const ViewUserDetails = ({ alert, onOpen = () => {} }) => {
  const { user, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const toggleResetPasswordModal = () => {
    setShowResetPassword(!showResetPassword);
  };

  const handleResendEmailClick = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { resendVerificationEmail } = await submitResendVerificationEmail();
      setIsLoading(false);
      if (resendVerificationEmail && resendVerificationEmail.code === 200) {
        alert.success(resendVerificationEmail.message);
      } else {
        alert.error(resendVerificationEmail);
      }
    } catch (err) {
      alert.error(err.message);
    }
  };

  return (
    <div className="user-info">
      {isLoading && (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      )}
      <div className="logo">
        {user.photo ? (
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${user.photo})`,
            }}
            title={`${user.firstName} ${user.lastName}`}
          />
        ) : (
          <div className="profile-image">
            <i
              title={`${user.firstName} ${user.lastName}`}
              style={{ fontSize: "3.5rem" }}
              className={getUserIcon(userRoleInOrg)}
            />
          </div>
        )}
      </div>
      <div className="row-format">
        <Row>
          <Col>
            <Label>First Name</Label>
            <Input defaultValue={user.firstName} disabled />
          </Col>
          <Col>
            <Label>Last Name</Label>
            <Input defaultValue={user.lastName} disabled />
          </Col>
        </Row>
      </div>
      <div className="row-format">
        <Label>Email</Label>
        <Input defaultValue={user.email} disabled />
        {!user.emailVerified && (
          <Alert color="warning" className="text-center">
            Your email verification is still pending <br />
            <a href="#" onClick={handleResendEmailClick}>
              Resend Verification Email
            </a>
          </Alert>
        )}
      </div>
      <div className="row-format">
        <Label>Phone</Label>
        <Input defaultValue={user.phone} disabled />
      </div>
      <Row>
        <Col>
          <Button
            className="font-12"
            color="link"
            size="sm"
            onClick={toggleResetPasswordModal}
          >
            <i className="fa fa-edit" /> Reset Password
          </Button>
          <ResetPassword
            isOpen={showResetPassword}
            onClose={toggleResetPasswordModal}
          />
        </Col>
        <Col className="text-end">
          <Button onClick={onOpen} size="sm" color="link" className="font-12">
            <i className="fa fa-edit" /> Edit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default withToast(ViewUserDetails);
