import { JOINORGANIZATION } from "#Graphql";
import withRouter from "#hoc/withRouter";
import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { withToast } from "#hoc/withToast";
import { Badge, Button } from "reactstrap";
import confirm from "#Components/ConfirmPopup";

const JoinOrganization = ({ authOrg, alert, onJoinSuccess = (x) => {} }) => {
  const [
    joinOrganization,
    { data: joinData, loading: joinLoading, error: joinError },
  ] = useMutation(JOINORGANIZATION);

  useEffect(() => {
    if (joinData?.joinOrganization?.code === 200) {
      alert.success(joinData.joinOrganization.message);
      onJoinSuccess(joinData.joinOrganization);
    }
  }, [joinData]);

  useEffect(() => {
    joinError && alert.error(joinError.message || "Unknown Error");
  }, [joinError]);

  const handleJoinOrganization = async (authOrg) => {
    if (authOrg) {
      await joinOrganization({ variables: { orgId: authOrg.id } });
    }
  };

  if (authOrg) {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    return authOrg.authorizedRole.role === "client_admin" ? (
      <Button
        className="me-1"
        size="sm"
        color="primary"
        outline
        disabled={joinLoading}
        onClick={async () => {
          const proceed = await confirm({
            title: `Join ${authOrg.name}`,
            message: (
              <>
                <p>
                  Joining <strong>{authOrg.name}</strong> organization will
                  remove you from your current organization,{" "}
                  <strong>{clvObj?.user?.organization?.name}</strong>. You will
                  then become an authorized administrator user in your current
                  organization.
                </p>
                <p>Are you sure you want to proceed? </p>
                <p className="text-center">
                  <Badge color="danger">
                    You'll be logged out, and you'll need to log in again
                  </Badge>
                </p>
              </>
            ),
            confirmText: "Join",
            confirmColor: "primary",
            cancelText: "Cancel",
            cancelColor: "link",
          });
          if (proceed) {
            await handleJoinOrganization(authOrg);
          }
        }}
      >
        <i
          title="Join Organization"
          className="fs-5 fa-solid fa-person-walking-arrow-right fa-flip-horizontal"
        />{" "}
        Join
      </Button>
    ) : null;
  }
};

export default withToast(withRouter(JoinOrganization));
