import { LEAVEORGANIZATION } from "#Graphql";
import withRouter from "#hoc/withRouter";
import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { withToast } from "#hoc/withToast";
import { Badge, Button } from "reactstrap";
import confirm from "#Components/ConfirmPopup";

const LeaveOrganization = ({ authOrg, alert, onLeaveSuccess = (x) => {} }) => {
  const [
    leaveAuthorizedOrganization,
    { data: leaveData, loading: leaveLoading, error: leaveError },
  ] = useMutation(LEAVEORGANIZATION);

  useEffect(() => {
    if (leaveData?.leaveAuthorizedOrganization?.code === 200) {
      alert.success(leaveData.leaveAuthorizedOrganization.message);
      onLeaveSuccess(leaveData.leaveAuthorizedOrganization);
    }
  }, [leaveData]);

  useEffect(() => {
    leaveError && alert.error(leaveError.message || "Unknown Error");
  }, [leaveError]);

  const handleLeaveOrganization = async (authOrg) => {
    if (authOrg) {
      await leaveAuthorizedOrganization({ variables: { orgId: authOrg.id } });
    }
  };

  if (authOrg) {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    return authOrg.authorizedRole.role === "client_admin" ? (
      <Button
        size="sm"
        color="danger"
        outline
        disabled={leaveLoading}
        onClick={async () => {
          const proceed = await confirm({
            title: `Leave ${authOrg.name}`,
            message: (
              <>
                <p>
                  Exiting <strong>{authOrg.name}</strong> will result in your
                  removal from the list of authorized users with access to this
                  organization.
                </p>
                <p>Are you sure you want to proceed? </p>
                {clvObj.org.id === authOrg.id ? (
                  <p className="text-center">
                    <Badge color="danger">
                      You'll be logged out, and you'll need to log in again
                    </Badge>
                  </p>
                ) : null}
              </>
            ),
            confirmText: "Leave",
            confirmColor: "danger",
            cancelText: "Cancel",
            cancelColor: "link",
          });
          if (proceed) {
            await handleLeaveOrganization(authOrg);
          }
        }}
      >
        <i
          title="Leave Organization"
          className="fs-5 fa-solid fa-person-walking-dashed-line-arrow-right"
        />{" "}
        Exit
      </Button>
    ) : null;
  }
};

export default withToast(withRouter(LeaveOrganization));
