import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { submitAssignClientToFTA } from "#Graphql/mutate";
import { fetchClouveAgentsList } from "#Graphql/query";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

class AgentListModal extends PureComponent {
  state = { isLoading: false, ftaList: [] };

  async componentDidMount() {
    await this.loadAgentList();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.orgId !== prevProps.orgId) {
      await this.loadAgentList();
    }
  }

  loadAgentList = async () => {
    const { clouveAgentsList } = await fetchClouveAgentsList("agent_admin");
    this.setState({ ftaList: clouveAgentsList });
  };

  renderAgentList = () => {
    const { ftaList = [] } = this.state;
    return ftaList.map((item, index) => (
      <option key={item.id} value={item.id}>
        {`${item.firstName} ${item.lastName}`}
      </option>
    ));
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    const { onAssign = () => {} } = this.props;
    this.setState({ isLoading: false });
    this.props.alert.success(message);
    onAssign();
  };

  assignClientFTASubmit = async ({ formFields }) => {
    const { orgId } = this.props;
    try {
      this.setState({ isLoading: true });
      const { assignClientToFTA } = await submitAssignClientToFTA({
        orgId,
        userId: Number(formFields.ftaAgent),
      });
      if (assignClientToFTA && assignClientToFTA.code === 200) {
        this.showSuccessMessage(assignClientToFTA.message);
      } else {
        this.errorHandler(assignClientToFTA);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render = () => {
    const { showModal, toggle, className } = this.props;
    const { isLoading } = this.state;

    return (
      <div>
        <Modal
          toggle={toggle}
          isOpen={showModal}
          backdrop={true}
          className={className}
        >
          {isLoading && (
            <div className="loading-spinner">
              <Spinner type="grow" />
            </div>
          )}
          <ModalHeader toggle={toggle}>Assign To</ModalHeader>
          <InputForm onSubmit={this.assignClientFTASubmit}>
            <ModalBody className="p-5">
              <Row>
                <Col sm="12" className="mb-4">
                  <Label>First Tier Agent</Label>
                  <SelectField name="ftaAgent">
                    <option value="" disabled>
                      Please select
                    </option>
                    {this.renderAgentList()}
                  </SelectField>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={toggle}>
                Cancel
              </Button>
              <SubmitButton color="primary">Assign</SubmitButton>
            </ModalFooter>
          </InputForm>
        </Modal>
      </div>
    );
  };
}

export default withToast(AgentListModal);
