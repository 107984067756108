import { withAuth } from "#hoc/auth";
import withRouter from "#hoc/withRouter";
import { withToast } from "#hoc/withToast";
import React, { PureComponent } from "react";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";

class App extends PureComponent {
  state = {
    showSidebar: true,
    width: 0,
    height: 0,
  };

  componentDidMount() {
    setTimeout(() => {
      const afterLoginURI = localStorage.getItem("afterLoginURI");
      if (afterLoginURI) {
        localStorage.removeItem("afterLoginURI");
        this.props.history.push(afterLoginURI);
      }
    }, 0);
    document.body.classList.add("auth-user");
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("click", this.handleDocumentClick);
    this.updateWindowDimensions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.width !== this.state.width) {
      this.setState({ showSidebar: this.state.width > 800 });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-user");
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (e) => {
    if (this.state.width < 800) {
      this.setState({
        showSidebar: false,
      });
    }
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  toggleSidebar = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  render() {
    const { showSidebar } = this.state;
    const match = { path: "/dashboard" };
    const classNames = ["page-slide", "main-app-container"];
    !showSidebar && classNames.push("no-sidebar");
    return (
      <div className="main-app-container">
        <Header toggleSidebar={this.toggleSidebar} showSidebar={showSidebar} />
        <div className={classNames.join(" ")}>
          <Sidebar
            match={match}
            toggleSidebar={this.toggleSidebar}
            showSidebar={showSidebar}
          />
          <Content />
        </div>
      </div>
    );
  }
}

export default withToast(withRouter(withAuth(App)));
