import { Link } from "react-router-dom";
import { Alert, Col, Row, Spinner } from "reactstrap";

const DeploymentProgress = (props) => {
  const { selfServiceBuildStatus = {} } = props;
  const { buildStatus, selfServiceAction } = selfServiceBuildStatus;
  if (!selfServiceAction) {
    return null;
  }
  return (
    <>
      {(buildStatus + "").match(/^(success|working)$/gi) ? (
        <Alert color="warning">
          <Row>
            <Col>
              Our automated assistant is currently processing your request.
              Please be aware that it might require a few minutes to complete.
            </Col>
            <Col xs="auto">
              <Spinner color="primary" />
            </Col>
          </Row>
        </Alert>
      ) : (
        <Alert color="danger">
          The action <strong>{selfServiceAction}</strong> was unsuccessful.
          Kindly <Link to="/support/create">Create a Support Ticket</Link> so
          our specialists can investigate the issue.
        </Alert>
      )}
    </>
  );
};
export default DeploymentProgress;
