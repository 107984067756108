import { InfoColumn, InfoTable } from "#Components/InfoTable";
import { logoutUser, switchOrganization } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import AddOrganizationDialog from "#pages/Profile/MyOrganizations/AddOrganization";
import JoinOrganization from "#pages/Profile/MyOrganizations/JoinOrganization";
import LeaveOrganization from "#pages/Profile/MyOrganizations/LeaveOrganization";
import React, { useState } from "react";
import { withToast } from "#hoc/withToast";
import { Button } from "reactstrap";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import ViewCompanyDetails from "./ViewCompanyDetails";

const MyOrganizations = (props) => {
  const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
  if (!clvObj) {
    return null;
  }
  const { user } = clvObj;
  const { authorizedOrgs = [] } = user;

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(clvObj.user.authorizedOrgs.length);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const renderAuthOrgIcon = (authOrg) => {
    if (authOrg) {
      return authOrg.logo ? (
        <img
          style={{ width: "3rem", height: "3rem" }}
          src={authOrg.logo}
          alt=""
        />
      ) : (
        <div className="fs-1 p-2 profile-image">
          <i title={authOrg.name} className="fa-regular fa-building" />
        </div>
      );
    }
  };

  const onAddSuccess = (authOrg) => {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    clvObj.user.authorizedOrgs.push(authOrg);
    sessionStorage.setItem("Clouve.object", JSON.stringify(clvObj));
    setCount(clvObj.user.authorizedOrgs.length);
  };

  const onJoinSuccess = async () => {
    await logoutUser(props);
  };

  const onAddUsers = async (authOrg) => {
    try {
      const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
      if (clvObj.org.id !== authOrg.id) {
        await switchOrganization(authOrg, "/people?action=add");
      } else {
        props.history.push("/people?action=add");
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  const onLeaveSuccess = async ({ orgId: authOrgId }) => {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    if (clvObj.org.id === authOrgId) {
      await logoutUser(props);
    } else {
      clvObj.user.authorizedOrgs = clvObj.user.authorizedOrgs.filter(
        (org) => org.id !== authOrgId,
      );
      sessionStorage.setItem("Clouve.object", JSON.stringify(clvObj));
    }
    setCount(clvObj.user.authorizedOrgs.length);
  };

  const renderAccessLevel = (authOrg) => {
    if (authOrg) {
      return authOrg.authorizedRole.role === "client_admin" ? "Admin" : "User";
    }
  };

  const renderActions = (authOrg) => {
    return (
      <>
        <JoinOrganization authOrg={authOrg} onJoinSuccess={onJoinSuccess} />
        <Button
          size="sm"
          color="primary"
          className="me-1"
          outline
          onClick={async () => {
            await onAddUsers(authOrg);
          }}
        >
          <i title="Add Users" className="fs-5 fa-solid fa-user-plus" />
          Add Users
        </Button>
        <LeaveOrganization authOrg={authOrg} onLeaveSuccess={onLeaveSuccess} />
      </>
    );
  };

  const renderAuthOrgs = () => {
    return (
      <>
        <hr />
        <h5>My Authorized Organizations</h5>
        <p>The list of organizations you are authorized to access</p>
        <InfoTable
          data={authorizedOrgs}
          pagination={authorizedOrgs.length > 10}
          highlightOnHover
          toolboxComponent={
            <AddOrganizationDialog onAddSuccess={onAddSuccess} />
          }
        >
          <InfoColumn width="3.5rem" cell={renderAuthOrgIcon} center>
            <span className="d-none">{count}</span>
          </InfoColumn>
          <InfoColumn selector={(org) => org.name} grow={2} sortable>
            Organization Name
          </InfoColumn>
          <InfoColumn
            width="150px"
            selector={(org) => org.authorizedRole?.role}
            cell={renderAccessLevel}
            sortable
            center
          >
            My Role
          </InfoColumn>
          {user.organizationRole.isClientAdmin ? (
            <InfoColumn width="350px" cell={renderActions} center />
          ) : null}
        </InfoTable>
      </>
    );
  };

  return (
    <>
      {isOpen ? (
        <UpdateCompanyDetails onOpen={onOpen} onClose={onClose} />
      ) : (
        <ViewCompanyDetails onOpen={onOpen} onClose={onClose} />
      )}
      {renderAuthOrgs()}
    </>
  );
};

export default withToast(withRouter(MyOrganizations));
