import React from "react";
import { createRoot } from "react-dom/client";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

/**
 * A confirmation dialog component that uses React 19's createRoot API
 * This component renders a modal dialog and returns a Promise that resolves to true (confirm) or false (cancel)
 */
const confirm = (props = {}) => {
  return new Promise((resolve) => {
    // Create a container for our modal
    const container = document.createElement("div");
    document.body.appendChild(container);

    // Create a root for React to render into
    const root = createRoot(container);

    // Default props
    const {
      title = "Confirm",
      message = "Are you sure?",
      confirmText = "OK",
      confirmColor = "primary",
      cancelText = "Cancel",
      cancelColor = "secondary",
      size = undefined,
      className = undefined,
      centered = true,
      animation = true,
      backdrop = true,
      keyboard = true,
      ...rest
    } = props;

    // Function to clean up the modal
    const cleanup = () => {
      root.unmount();
      document.body.removeChild(container);
    };

    // Function to handle confirmation
    const handleConfirm = () => {
      cleanup();
      resolve(true);
    };

    // Function to handle cancellation
    const handleCancel = () => {
      cleanup();
      resolve(false);
    };

    // Render the modal
    root.render(
      <Modal
        isOpen={true}
        toggle={handleCancel}
        size={size}
        className={className}
        centered={centered}
        fade={animation}
        backdrop={backdrop}
        keyboard={keyboard}
        {...rest}
      >
        {title && <ModalHeader toggle={handleCancel}>{title}</ModalHeader>}
        {message && <ModalBody>{message}</ModalBody>}
        <ModalFooter>
          <Button color={cancelColor} onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button color={confirmColor} onClick={handleConfirm}>
            {confirmText}
          </Button>
        </ModalFooter>
      </Modal>,
    );
  });
};

export default confirm;
