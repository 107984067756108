import ErrorBoundary from "#Components/ErrorBoundary";
import FileUploader from "#Components/FileUploader";
import InputForm, {
  SelectField,
  SubmitButton,
  TextareaField,
  TextField,
} from "#Components/InputForm";
import { submitCreateTicket } from "#Graphql/mutate";
import { fetchTicketFields } from "#Graphql/query";
import { getUserIcon } from "#hoc/util";
import withRouter from "#hoc/withRouter";

import moment from "moment";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["title", "deploymentId"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  return errors;
};

class CreateSupport extends PureComponent {
  state = {
    urgencies: [],
    getDeploymentsList: [],
    attachments: [],
    isLoading: false,
  };

  async componentDidMount() {
    await this.loadTicketFields();
  }

  loadTicketFields = async () => {
    try {
      this.setState({ isLoading: true });
      const ticketFields = await fetchTicketFields();
      this.setState({ ...ticketFields, isLoading: false });
    } catch (err) {
      this.errorHandler(err);
    }
  };

  errorHandler = (errors) => {
    this.setState({ isLoading: false });
    this.props.alert.error(errors.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  renderUrgencies = () => {
    const { urgencies } = this.state;
    if (urgencies) {
      return urgencies.map(({ name, value, disabled }) => {
        if (value.toLowerCase() === "minor") {
          return (
            <option key={value} value={value} disabled={disabled}>
              {name}
            </option>
          );
        } else {
          return (
            <option key={value} value={value} disabled={disabled}>
              {name}
            </option>
          );
        }
      });
    }
    return "";
  };

  renderDeployments = () => {
    const { getDeploymentsList } = this.state;
    if (getDeploymentsList) {
      return getDeploymentsList.map(({ id, ticket }) => (
        <option key={id} value={id}>
          {ticket.title}
        </option>
      ));
    }
    return "";
  };

  onFileUpload = (attachments) => {
    this.setState({ attachments });
  };

  renderTicketFooter = () => {
    return (
      <div className="add-ticket-footer text-center">
        <Row className="p-2">
          <Col>
            Need help creating a deployment? &mdash;{" "}
            <Link to={"/help"}>HELP</Link>
          </Col>
        </Row>
      </div>
    );
  };

  handleCreateTicket = async ({ formFields }) => {
    const { title, urgency, description, deploymentId } = formFields;
    const { attachments } = this.state;

    const input = {
      type: "SUPPORT",
      title,
      urgency,
      description,
      deploymentId: deploymentId ? parseInt(deploymentId) : null,
      attachments,
      currentTime: moment.utc().format(),
    };

    try {
      this.setState({ isLoading: true });
      const { createTicket } = await submitCreateTicket({ input });
      if (createTicket && createTicket.code === 201) {
        this.showSuccessMessage(createTicket.message);
        this.props.history.push(`/support/${createTicket.orgTicketId}`);
      } else {
        this.errorHandler(createTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  handleCancel = () => {
    this.props.history.push("/tickets");
  };

  renderCreateTicket = () => {
    const { fta } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    return (
      <div className="ticket-form">
        <div className="text-center pt-4 pb-4">
          <h4>Create Support Ticket</h4>
          <h5>
            Let our experts know about issues related to any installed App
          </h5>
          <h6>
            Please describe the issue in details so that our experts can look
            into it and resolve ASAP
          </h6>
        </div>
        <div className="add-ticket-body">
          <InputForm
            initialValues={{
              urgency: "minor",
              assigneeId: fta?.id,
              assignee: fta?.email,
            }}
            validator={validate}
            onSubmit={this.handleCreateTicket}
          >
            <Row>
              <Col sm={6}>
                <Label>Title *</Label>
                <TextField name="title" />
              </Col>
              <Col sm="6">
                <Label>Urgency</Label>
                <SelectField name="urgency">
                  {this.renderUrgencies()}
                </SelectField>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Label>Description</Label>
                <TextareaField name="description" />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Label>App *</Label>
                <SelectField name="deploymentId">
                  <option value="">[select app]</option>
                  {this.renderDeployments()}
                </SelectField>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="12" className="automatic-assign">
                <Label>Assigned Expert</Label>
                <InputGroup disabled>
                  <InputGroupText>
                    <i className={getUserIcon({ isAgent: true })} /> Automatic:
                  </InputGroupText>
                  <TextField name="assignee" disabled />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <FileUploader onFileUpload={this.onFileUpload} />
              </Col>
            </Row>

            <Row>
              <Col className="text-end mb-5">
                <SubmitButton size="sm" className="me-2" color="primary">
                  Create
                </SubmitButton>
                <Button size="sm" color="link" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </InputForm>
        </div>
      </div>
    );
  };

  render() {
    let { loading } = this.props;
    return (
      <Card className="content-wrapper create-ticket-wrapper">
        <ErrorBoundary error="bg-error">
          <div className="ticket-box">
            {loading ? (
              <div className="loading-spinner">
                <Spinner type="grow" />
              </div>
            ) : (
              <Container>{this.renderCreateTicket()}</Container>
            )}

            {this.renderTicketFooter()}
          </div>
        </ErrorBoundary>
      </Card>
    );
  }
}

export default withRouter(withToast(CreateSupport));
