import { checkEmailExists } from "#Graphql/query";
import { emailRegex, phoneRegex } from "#hoc/util";

export const getLoginValidator = (requiredFields) => {
  return async (values) => {
    const errors = {};
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "This field is required";
      }
    });
    if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };
};

export const ForgotPasswordValidator = (values) => {
  const errors = {};
  const requiredFields = ["email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  if (values.email && !emailRegex.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const SignUpValidator = async (
  values,
  { prevValues, initialValues },
) => {
  const errors = {};
  try {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "orgName",
      "agreement",
      "newPassword",
      "confirmPassword",
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "This field is required";
      }
    });
    if (!values.hasOwnProperty("agreement")) {
      errors.agreement = "This field is required";
    } else if (values.agreement === false) {
      errors.agreement = "Please accept the terms and conditions";
    }
    if (values.phone && values.phone !== prevValues.phone) {
      if (!phoneRegex.test(values.phone)) {
        errors.phone = "Invalid phone number";
      }
    }
    if (values.email && values.email !== prevValues.email) {
      if (!emailRegex.test(values.email)) {
        errors.email = "Invalid email address";
      } else {
        const { checkEmail = {} } = await checkEmailExists(values["email"]);
        const { userData } = checkEmail;
        if (userData) {
          errors.email = "This email already exists";
        }
      }
    }
  } catch (e) {
    errors.agreement = e.message;
  } finally {
    return errors;
  }
};
