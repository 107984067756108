import ErrorBoundary from "#Components/ErrorBoundary";
import { withNoAuth } from "#hoc/auth";
import withRouter from "#hoc/withRouter";
import ForgotPassword from "#pages/Account/ForgotPassword";
import Login from "#pages/Account/Login";
import ResetPassword from "#pages/Account/ResetPassword";
import SignUp from "#pages/Account/SignUp";
import React from "react";
import { withToast } from "#hoc/withToast";

const CDN_SERVER = `https://${window.VITE_CDN_HOSTNAME || "cdn.clouve.com"}`;

const AccountPage = (props) => {
  const landingPageMap = {
    "/login": <Login />,
    "/signup": <SignUp />,
    "/signup/success": <SignUp />,
    "/forgot-password": <ForgotPassword />,
    "/reset-password": <ResetPassword />,
  };
  const landingPage = landingPageMap[props.location.pathname];
  const rnd = Math.floor(Math.random() * 5) + 1;
  return (
    <div className="account-wrapper">
      <div className="account">
        <ErrorBoundary error="bg-error">
          <div
            className="image-placeholder"
            style={{
              backgroundImage: `url(${CDN_SERVER}/assets/images/clouve-bg-img-${rnd}.jpg)`,
            }}
          >
            <div className="image-plc-text">
              <span>Shoot for the moon,</span>
              <span>Land your IT on the cloud.</span>
            </div>
          </div>
        </ErrorBoundary>
        <ErrorBoundary error="bg-error">{landingPage}</ErrorBoundary>
      </div>
    </div>
  );
};
export default withToast(withRouter(withNoAuth(AccountPage)));
