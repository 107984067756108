import React from "react";
import { showToast } from "../main";

/**
 * A utility HOC that mimics the behavior of react-alert's withAlert HOC
 * to make migration easier.
 *
 * @param {React.Component} Component - The component to wrap
 * @returns {React.Component} - The wrapped component with alert prop
 */
export const withToast = (Component) => {
  const WithToastComponent = (props) => {
    // Create an alert object that mimics react-alert's interface
    const alert = {
      success: showToast.success,
      error: showToast.error,
      info: showToast.info,
      show: showToast.info, // 'show' in react-alert maps to 'info' in react-toastify
    };

    // Pass the alert object as a prop
    return <Component {...props} alert={alert} />;
  };

  // Set display name for debugging
  const displayName = Component.displayName || Component.name || "Component";
  WithToastComponent.displayName = `WithToast(${displayName})`;

  return WithToastComponent;
};

// For functional components using hooks
export const useToast = () => {
  return {
    success: showToast.success,
    error: showToast.error,
    info: showToast.info,
    show: showToast.info,
  };
};

export default withToast;
