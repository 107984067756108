import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Button, Spinner } from "reactstrap";

class TicketUrgency extends PureComponent {
  state = {
    isLoading: false,
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "urgency") {
      this.setState({ isOpen: false });
    }
  }

  renderUrgencies = () => {
    const { urgencies } = this.props;
    if (urgencies) {
      return urgencies.map(({ name, value, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {name}
        </option>
      ));
    }
    return "";
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  updateTicketUrgencySubmit = async ({ formFields }) => {
    const { ticketDetails, onUpdate = () => {} } = this.props;
    this.setState({ isLoading: true });
    try {
      const { urgency } = formFields;
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input: { urgency },
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { isOpen, isLoading } = this.state;
    const canEdit = ticketDetails?.state === TICKET_STATES.open;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isClient && canEdit) {
      return (
        <div className="editable-block">
          {isOpen ? (
            <div className="editable-field ticket-urgency">
              <InputForm
                initialValues={{ urgency: ticketDetails?.urgency }}
                onSubmit={this.updateTicketUrgencySubmit}
              >
                <SelectField name="urgency">
                  {this.renderUrgencies()}
                </SelectField>
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("urgency")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : (
            <span
              className="hover-edit"
              onClick={() => this.handleEditFields("urgency")}
              title="Click here to edit"
            >
              {ticketDetails?.urgency?.toUpperCase()}
              <i className="fa fa-pencil" />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">
          {ticketDetails?.urgency?.toUpperCase()}
        </div>
      );
    }
  }
}

export default withToast(TicketUrgency);
