import Switch from "#Components/Switch";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, FormFeedback, Input, Progress } from "reactstrap";
import zxcvbn from "zxcvbn";

export const FormContext = createContext({});

const getFieldProps = ({
  formContext,
  props,
  forceValidateOnChange = false,
  submitOnEnter = false,
}) => {
  const { validateOnBlur, ...fieldProps } = props;
  const {
    formFields = {},
    setFormField = () => {},
    getFieldError = () => {},
    submitForm = async () => {},
    FieldProps = {},
  } = formContext;
  const error = getFieldError(fieldProps.name);
  const commonProps = {
    ...fieldProps,
    invalid: !!error,
  };
  if (submitOnEnter) {
    commonProps.onKeyDown = (evt) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        (async () => {
          await submitForm(evt);
        })();
        props.onKeyDown && props.onKeyDown(evt);
      }
    };
  }
  if (!forceValidateOnChange && (FieldProps.validateOnBlur || validateOnBlur)) {
    return {
      ...commonProps,
      defaultValue: formFields[fieldProps.name] || "",
      onBlur: (evt) => {
        setFormField({ [fieldProps.name]: evt.target.value });
        fieldProps.onBlur && fieldProps.onBlur(evt);
      },
    };
  } else {
    return {
      ...commonProps,
      value: formFields[fieldProps.name] || "",
      onChange: (evt) => {
        setFormField({ [fieldProps.name]: evt.target.value });
        fieldProps.onChange && fieldProps.onChange(evt);
      },
    };
  }
};

export const TextField = (props) => {
  const formContext = useContext(FormContext);
  const { setFormField = () => {}, submitForm = async () => {} } = formContext;
  const error = formContext.getFieldError(props.name);
  useEffect(() => {
    props.value && setFormField({ [props.name]: props.value });
  }, [props.value]);
  return (
    <>
      <Input
        {...getFieldProps({ formContext, props, submitOnEnter: true })}
        type="text"
      />
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export const PhoneField = (props) => {
  const formContext = useContext(FormContext);
  const { setFormField = () => {} } = formContext;
  const error = formContext.getFieldError(props.name);

  // Track if the field has been touched (blurred)
  const [touched, setTouched] = useState(false);

  // Format phone number with dashes
  const formatPhoneNumber = (value = "") => {
    const digits = value.replace(/\D/g, "");

    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  };

  // Update form field when props.value changes
  useEffect(() => {
    if (props.value) {
      setFormField({ [props.name]: formatPhoneNumber(props.value) });
    }
  }, [props.value, props.name, setFormField]);

  // Custom handlers for formatting phone numbers
  const handleChange = (evt) => {
    const formattedValue = formatPhoneNumber(evt.target.value);
    setFormField({ [props.name]: formattedValue });
    props.onChange &&
      props.onChange({
        ...evt,
        target: { ...evt.target, value: formattedValue },
      });
  };

  const handleBlur = (evt) => {
    setTouched(true);
    const formattedValue = formatPhoneNumber(evt.target.value);
    setFormField({ [props.name]: formattedValue });
    props.onBlur &&
      props.onBlur({
        ...evt,
        target: { ...evt.target, value: formattedValue },
      });
  };

  // Get field props with forceValidateOnChange to ensure we get a controlled input
  const fieldProps = getFieldProps({
    formContext,
    props,
    forceValidateOnChange: true,
    submitOnEnter: true,
  });

  // Only show validation errors if the field has been touched
  const showError = touched || !props.validateOnBlur;
  const isInvalid = showError && !!error;

  // Extract the value from fieldProps
  const { value, onChange, onBlur, ...safeFieldProps } = fieldProps;

  return (
    <>
      <Input
        {...safeFieldProps}
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={isInvalid}
        type="tel"
        placeholder="___-___-____"
        maxLength={12}
      />
      {isInvalid && error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export const PasswordField = ({
  allowShowPassword = true,
  showStrengthBar,
  ...props
}) => {
  const formContext = useContext(FormContext);
  const { formFields = {}, setFormField = () => {} } = formContext;
  const error = formContext.getFieldError(props.name);
  useEffect(() => {
    props.value && setFormField({ [props.name]: props.value });
  }, [props.value]);
  const [showPassword, setShowPassword] = useState(false);
  const evaluation = zxcvbn(formFields[props.name] || "");
  const strength = { value: evaluation.score * 25 };
  switch (evaluation.score) {
    case 0:
      strength.label = "Very Weak";
      strength.color = "danger";
      break;
    case 1:
      strength.label = "Very Weak";
      strength.color = "danger";
      break;
    case 2:
      strength.label = "Weak";
      strength.color = "warning";
      break;
    case 3:
      strength.label = "Medium";
      strength.color = "info";
      break;
    case 4:
      strength.label = "Strong";
      strength.color = "success";
      break;
  }

  return (
    <div className="password-field">
      <Input
        {...getFieldProps({ formContext, props, submitOnEnter: true })}
        type={showPassword ? "text" : "password"}
        minLength={8}
      />
      {allowShowPassword && !error && (
        <div
          className="show-password-btn"
          onClick={() => setShowPassword(!showPassword)}
        >
          <i
            className={
              showPassword ? "fa fa-eye-slash" : "fa fa-eye-slash d-none"
            }
          />
          <i className={showPassword ? "fa fa-eye d-none" : "fa fa-eye"} />
        </div>
      )}
      {error ? (
        <FormFeedback>{error}</FormFeedback>
      ) : (
        showStrengthBar && (
          <Progress className="password-strength" {...strength}>
            {strength.label}
          </Progress>
        )
      )}
    </div>
  );
};
export const SwitchField = (props) => {
  const formContext = useContext(FormContext);
  const { setFormField = () => {} } = formContext;
  const error = formContext.getFieldError(props.name);
  useEffect(() => {
    props.hasOwnProperty("checked") &&
      setFormField({ [props.name]: props.checked });
  }, [props.checked]);
  return (
    <>
      <Switch
        {...getFieldProps({ formContext, props, forceValidateOnChange: true })}
        onChange={(checked) => {
          setFormField({ [props.name]: checked });
          props.onChange && props.onChange(checked);
        }}
      />
      {error && <FormFeedback className="text-center">{error}</FormFeedback>}
    </>
  );
};

export const SelectField = ({ children, ...props }) => {
  const formContext = useContext(FormContext);
  const { setFormField = () => {} } = formContext;
  const error = formContext.getFieldError(props.name);
  useEffect(() => {
    props.value && setFormField({ [props.name]: props.value });
  }, [props.value]);
  return (
    <>
      <Input
        {...getFieldProps({ formContext, props, forceValidateOnChange: true })}
        type="select"
      >
        {children}
      </Input>
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export const TextareaField = ({ children, ...props }) => {
  const formContext = useContext(FormContext);
  const { setFormField = () => {} } = formContext;
  const error = formContext.getFieldError(props.name);
  useEffect(() => {
    props.value && setFormField({ [props.name]: props.value });
  }, [props.value]);
  return (
    <>
      <Input
        {...getFieldProps({ formContext, props, forceValidateOnChange: true })}
        type="textarea"
        rows="10"
      >
        {children}
      </Input>
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export const SubmitButton = ({ children, disabled, ...props }) => {
  const { invalid, submitForm = async () => {} } = useContext(FormContext);
  return (
    <Button
      {...props}
      disabled={disabled || invalid}
      onClick={(evt) => {
        (async () => {
          await submitForm(evt);
          props.onClick && props.onClick(evt);
        })();
      }}
    >
      {children}
    </Button>
  );
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function InputForm(props) {
  const loadRef = useRef();
  const stateRef = useRef();
  const {
    initialValues = {},
    validateOnLoad = false,
    validateOnBlur = false,
    validatorOptions,
    onChange = (x) => {},
    onLoad = () => {},
    onValidate = (x) => {},
    validator = async (x) => ({}),
    onSubmit = async () => {},
    children,
  } = props;
  const [formFields, setFormFields] = useState(
    JSON.parse(JSON.stringify(initialValues)),
  );
  const [formErrors, setFormErrors] = useState({});
  const prevFormFields = usePrevious(formFields);
  const invalid = !!Object.keys(formErrors).length;

  const validateForm = async () => {
    setFormErrors(
      await validator(formFields, {
        prevValues: prevFormFields,
        initialValues,
        validatorOptions,
      }),
    );
  };

  const submitForm = async (evt) => {
    !invalid && (await onSubmit({ formFields, initialValues }, evt));
  };

  useEffect(() => {
    (async () => {
      await validateForm();
      onLoad();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await validateForm();
    })();
  }, [validatorOptions]);

  useEffect(() => {
    if (stateRef.current) {
      stateRef.current = null;
    }
    if (loadRef.current) {
      (async () => {
        await validateForm();
        onChange({ formFields, formErrors });
      })();
    } else {
      (async () => {
        validateOnLoad && (await validateForm());
      })();
      loadRef.current = true;
    }
  }, [formFields]);

  useEffect(() => {
    onValidate(formErrors);
  }, [formErrors]);

  return (
    <FormContext.Provider
      value={{
        initialValues,
        formFields,
        prevFormFields,
        setFormField: (fields) => {
          //accumulating formFields changes before actually setting them all at once
          stateRef.current = stateRef.current || { ...formFields };
          stateRef.current = { ...stateRef.current, ...fields };
          setFormFields(stateRef.current);
        },
        validator,
        submitForm,
        invalid,
        getFieldError: (field) =>
          formFields.hasOwnProperty(field) ? formErrors[field] : "",
        FieldProps: { validateOnBlur },
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
