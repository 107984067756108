import LogoUploader from "#Components/FileUploader/LogoUploader";
import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import { submitUpdateMyProfile } from "#Graphql/mutate";
import { getUserIcon } from "#hoc/util";
import { validateUpdateUserDetails } from "#pages/Profile/MyProfile/helpers";
import React, { useState } from "react";
import { withToast } from "#hoc/withToast";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";

const UpdateUserDetails = ({ alert, onClose = () => {} }) => {
  const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const { user, userRoleInOrg } = clvObj;
  const [isLoading, setIsLoading] = useState(false);
  const [tempPhoto, setTempPhoto] = useState(null);

  const onFileUpload = (uploadedFiles = []) => {
    setTempPhoto(uploadedFiles[0]);
  };

  const showSuccessMessage = (message) => {
    setIsLoading(false);
    onClose();
    alert.success(message);
  };

  const errorHandler = (error) => {
    setIsLoading(false);
    alert.error(error.message);
  };

  const onSaveUserDetails = async ({ formFields }) => {
    setIsLoading(true);
    const input = {
      firstName: formFields.firstName,
      lastName: formFields.lastName,
      email: formFields.email,
      phone: formFields.phone,
      photo: tempPhoto?.fileUrl || user.photo || "",
    };

    try {
      const { updateMyProfile } = await submitUpdateMyProfile({ input });
      if (updateMyProfile && updateMyProfile.code === 200) {
        const newClouveObj = {
          ...clvObj,
          user: { ...user, ...input },
        };
        if (user.email !== formFields.email) {
          newClouveObj.user.emailVerified = false;
        }
        sessionStorage.setItem("Clouve.object", JSON.stringify(newClouveObj));
        showSuccessMessage(updateMyProfile.message);
      } else {
        errorHandler(updateMyProfile);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <div className="user-info">
      {isLoading && (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      )}
      <InputForm
        initialValues={user}
        onSubmit={onSaveUserDetails}
        validator={validateUpdateUserDetails}
      >
        <FormGroup className="logo">
          <LogoUploader onFileUpload={onFileUpload} />
          {user.photo ? (
            <div
              className="profile-image"
              style={{
                backgroundImage: `url(${user.photo})`,
              }}
              title={`${user.firstName} ${user.lastName}`}
            />
          ) : (
            <div className="profile-image">
              <i
                title={`${user.firstName} ${user.lastName}`}
                style={{ fontSize: "3.5rem" }}
                className={getUserIcon(userRoleInOrg)}
              />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <Label for="firstName">First Name *</Label>
              <TextField id="firstName" name="firstName" />
            </Col>
            <Col>
              <Label for="lastName">Last Name *</Label>
              <TextField id="lastName" name="lastName" />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label for="userEmail">Email *</Label>
          <TextField id="userEmail" name="email" type="email" validateOnBlur />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <TextField id="phone" name="phone" type="phone" />
        </FormGroup>
        <div className="text-end">
          <SubmitButton color="primary" size="sm">
            Save
          </SubmitButton>
          <Button color="link" onClick={onClose} size="sm">
            Cancel
          </Button>
        </div>
      </InputForm>
    </div>
  );
};

export default withToast(UpdateUserDetails);
