import FileUploader from "#Components/FileUploader";
import { DELETEATTACHMENTBYID } from "#Graphql";
import { client } from "#hoc/config";
import React, { useState } from "react";
import { withToast } from "#hoc/withToast";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const ImagePreview = ({ item }) => {
  const [showPreview, setShowPreview] = useState(false);
  const closeImagePreview = () => {
    setShowPreview(false);
  };
  const openImagePreview = () => {
    setShowPreview(true);
  };
  return (
    <>
      <span
        role="button"
        className="attached-img"
        style={{ backgroundImage: `url(${item.fileUrl})` }}
        title={item.fileName}
        onClick={openImagePreview}
      />
      <Modal
        size="lg"
        isOpen={showPreview}
        toggle={closeImagePreview}
        fade={false}
      >
        <ModalHeader toggle={closeImagePreview}>{item.fileName}</ModalHeader>
        <ModalBody>
          <img
            src={item.fileUrl}
            alt={item.fileName}
            style={{ width: "100%" }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="link" size="sm" onClick={closeImagePreview}>
            Close
          </Button>
          <Button
            color="primary"
            size="sm"
            href={item.fileUrl}
            download={item.fileName}
          >
            Download
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

class FileAttachment extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.getTicketForm();
  }

  componentDidUpdate(prevProps) {
    const { ticketDetails } = this.props;
    if (ticketDetails && prevProps.ticketDetails.id !== ticketDetails.id) {
      this.getTicketForm();
    }
  }

  getTicketForm = () => {
    const { ticketDetails } = this.props;
    if (ticketDetails && ticketDetails.id) {
      this.setState({ ...ticketDetails });
    }
  };

  errorHandler = (error) => {
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.props.alert.success(message);
  };

  onFileUpload = async (uploadedFiles) => {
    const { onUpload = (x) => {} } = this.props;
    onUpload(uploadedFiles);
  };

  deleteTicketAttachmentSubmit = async (item) => {
    this.setState({ isLoading: true });
    try {
      const { ticketDetails, onDelete = () => {} } = this.props;
      let response = await client.query({
        query: DELETEATTACHMENTBYID,
        variables: {
          ticketId: ticketDetails.id,
          imageId: item.id,
        },
        fetchPolicy: "network-only",
      });

      if (response) {
        const updateTicket = response.data.deleteAttachmentById;
        if (updateTicket.code === 200) {
          this.showSuccessMessage(updateTicket.message);
          this.setState({ isLoading: false });
          onDelete();
        }
      } else {
        this.errorHandler(response);
        this.setState({ isLoading: false });
      }
    } catch (response) {
      this.errorHandler(response);
      this.setState({ isLoading: false });
    }
  };

  renderFileTypePreview = (item) => {
    if (["image/png", "image/jpg", "image/jpeg"].includes(item.fileType)) {
      return <ImagePreview item={item} />;
    } else if (item.fileType === "application/pdf") {
      return (
        <a href={item.fileUrl} download={item.fileName}>
          <i
            title={item.fileName}
            style={{ fontSize: "2rem", color: "black" }}
            className="fa fa-file-pdf-o"
          />
        </a>
      );
    } else if (
      ["application/docx", "application/doc"].includes(item.fileType)
    ) {
      return (
        <a href={item.fileUrl} target="_blank" download={item.fileName}>
          <i
            title={item.fileName}
            style={{ fontSize: "2rem" }}
            className="fa fa-file-text-o"
          />
        </a>
      );
    }
  };

  renderImageSize = (item) => {
    let imageSize = item.fileSize / 1000;
    return Math.round(imageSize);
  };

  renderAttachments = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { attachments } = this.props.ticketDetails;

    if (attachments && attachments.length) {
      return (
        <div className="file-preview">
          {attachments.map((item, idx) => (
            <div className="file-preview-detail" key={idx}>
              {this.renderFileTypePreview(item)}
              <div className="fp-remove">
                <span className="text-truncate">{item.fileName}</span>
                <span className="gray-medium small">
                  {this.renderImageSize(item)} kb
                </span>
                {item.addedBy === user.userId ? (
                  <Button
                    className="btn-light"
                    onClick={() => this.deleteTicketAttachmentSubmit(item)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  render() {
    const { ticketDetails } = this.props;
    const { isLoading } = this.state;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    return (
      <div className="editable-block">
        <div className="editable-field" style={{ width: "100%" }}>
          <FileUploader
            ticketId={ticketDetails.id}
            onFileUpload={this.onFileUpload}
            hideUploadedFiles={true}
          />
        </div>
        {this.renderAttachments()}
      </div>
    );
  }
}

export default withToast(FileAttachment);
