import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import { fetchPriorities } from "#Graphql/query";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Button, Spinner } from "reactstrap";

class TicketPriorities extends PureComponent {
  state = {
    priorities: [],
    isLoading: false,
    isOpen: false,
  };

  async componentDidMount() {
    const { priorities } = await fetchPriorities();
    this.setState({ priorities });
  }

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "priority") {
      this.setState({ isOpen: false });
    }
  }

  renderPriorityList = () => {
    const { priorities } = this.state;
    if (priorities) {
      return priorities.map(({ name, value, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {name}
        </option>
      ));
    }
    return "";
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  updatePrioritiesSubmit = async ({ formFields }) => {
    const { ticketDetails, onUpdate = () => {} } = this.props;
    try {
      const { priority } = formFields;
      this.setState({ isLoading: true });
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input: { priority },
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { isOpen, isLoading } = this.state;
    const canEdit = ticketDetails?.state === TICKET_STATES.open;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isAgent && canEdit) {
      return (
        <div className="editable-block">
          {!isOpen && !ticketDetails.priority ? (
            <Button
              color="link"
              size="sm"
              className="ps-0 font-12"
              onClick={() => this.handleEditFields("priority")}
            >
              Add Priority
            </Button>
          ) : null}

          {!isOpen && ticketDetails.priority ? (
            <span
              className="hover-edit"
              onClick={() => this.handleEditFields("priority")}
              title="Click here to edit"
            >
              {ticketDetails?.priority?.toUpperCase()}
              <i className="fa fa-pencil" />
            </span>
          ) : null}

          {isOpen ? (
            <div className="editable-field ticket-priority">
              <InputForm
                initialValues={{ priority: ticketDetails.priority }}
                onSubmit={this.updatePrioritiesSubmit}
              >
                <SelectField name="priority">
                  {this.renderPriorityList()}
                </SelectField>
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("priority")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">
          {ticketDetails?.priority?.toUpperCase()}
        </div>
      );
    }
  }
}

export default withToast(TicketPriorities);
