import DeploymentBackups from "#Components/AutoDeployment/DeploymentBackups";
import DeploymentLogs from "#Components/AutoDeployment/DeploymentLogs";
import DeploymentProgress from "#Components/AutoDeployment/DeploymentProgress";
import DeploymentStatus from "#Components/AutoDeployment/DeploymentStatus";
import PrivilegedActions from "#Components/AutoDeployment/PrivilegedActions";
import UserActions from "#Components/AutoDeployment/UserActions";
import Collapsable from "#Components/Collapsable";
import DynamicForm, {
  CAPACITY_ONLY,
  CLOUD_ONLY,
  DOMAINS_ONLY,
} from "#Components/DynamicForm";
import { validateClusterFields } from "#Components/DynamicForm/helpers";
import { SolutionItem } from "#Components/SolutionItem";
import TabNav from "#Components/TabNav";

import {
  cloneAction,
  UpActions,
  updateSubscriptionAction,
} from "#Constants/Endpoints";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateDeployment } from "#Graphql/mutate";
import {
  confirmCloneDeploymentAction,
  confirmDeploymentAction,
  isSubscriptionStatusAny,
} from "#hoc/util";
import withRouter from "#hoc/withRouter";
import _, { isEqual } from "lodash";
import React, { PureComponent } from "react";
import { withToast } from "#hoc/withToast";
import { Alert, Spinner, Table } from "reactstrap";
import confirm from "#Components/ConfirmPopup";

export const actionsMap = {
  subscribe: "primary",
  launch: "primary",
  clone: "success",
  upgrade: "primary",
  suspend: "warning",
  pause: "warning",
  resume: "success",
  backup: "primary",
  restore: "success",
  status: "secondary",
  delete: "danger",
  enable_sso: "primary",
  disable_sso: "primary",
  cancel_trial: "danger",
  cancel_subscription: "danger",
};
for (const key in actionsMap) {
  actionsMap[`agent/${key}`] = actionsMap[key];
}
export const subscriptionMap = {
  active: "success",
  exempt: "success",
  trial: "success",
  pending: "success",
  canceled: "danger",
  expired: "warning",
  pastdue: "warning",
};
export const buildMap = {
  unknown: "warning",
  queued: "warning",
  working: "warning",
  success: "success",
  failure: "danger",
  error: "danger",
  timeout: "danger",
  cancelled: "danger",
};

class AutoDeployment extends PureComponent {
  static ticketStatusTimer = null;
  static ticketStatusInterval = 30000; //30 sec
  static buildStatusTimer = null;
  static buildStatusInterval = 3000; //3 sec
  static deploymentStatusTimer = null;
  static deploymentStatusInterval = 15000; //15 sec

  constructor(props) {
    super(props);
    this.state = {
      ticketObj: {},
      formData: {},
      extraErrors: {},
      blurValidationPending: false,
      selfServiceBuildStatus: {
        buildId: "",
        buildProgress: 0,
        buildStatus: "",
        selfServiceAction: "",
        actionInProgress: false,
        actionSuccess: true,
      },
      selfServiceBuildLogs: "",
      deploymentStatus: {},
      deploymentDetails: {},
      isLoading: false,
    };

    this.isMountedRef = React.createRef();
  }

  isMounted() {
    return !!this.isMountedRef.current;
  }

  componentDidMount() {
    this.processTicketDetails();
    this.setupTimers();
  }

  componentWillUnmount() {
    this.teardownTimers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ticketDetails } = this.props;
    const {
      userToken,
      selfServiceBuildStatus,
      selfServiceBuildLogs,
      deploymentStatus,
      deploymentDetails,
      ...ticketObj
    } = ticketDetails;
    const {
      userToken: prevUserToken,
      selfServiceBuildStatus: prevSelfServiceBuildStatus,
      selfServiceBuildLogs: prevSelfServiceBuildLogs,
      deploymentStatus: prevDeploymentStatus,
      deploymentDetails: prevDeploymentDetails,
      ...prevTicketObj
    } = prevProps.ticketDetails;
    if (!isEqual(userToken, prevUserToken)) {
      this.processAgentToken();
    }
    if (
      !isEqual(selfServiceBuildStatus, prevSelfServiceBuildStatus) ||
      !isEqual(selfServiceBuildLogs, prevSelfServiceBuildLogs)
    ) {
      this.processBuildStatus();
    }
    if (
      !isEqual(deploymentStatus, prevDeploymentStatus) ||
      !isEqual(deploymentDetails, prevDeploymentDetails)
    ) {
      this.processDeploymentStatus();
    }
    if (!isEqual(ticketObj, prevTicketObj)) {
      this.processTicketObj();
    }
  }

  errorHandler = (errors) => {
    this.setState({ isLoading: false });
    this.props.alert.error(errors.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  processAgentToken = () => {
    const { ticketDetails } = this.props;
    const { userToken } = ticketDetails;
    userToken && this.setState({ userToken });
  };

  processBuildStatus = () => {
    const { ticketDetails } = this.props;
    const {
      selfServiceBuildStatus,
      selfServiceBuildLogs,
      deployment = {},
    } = ticketDetails;
    const stateObj = {};
    if (selfServiceBuildStatus) {
      const actionInProgress =
        selfServiceBuildStatus.buildProgress < 100 ||
        !selfServiceBuildStatus.buildStatus?.match(/^success$/gi);
      const actionSuccess =
        selfServiceBuildStatus.buildProgress === 100 &&
        selfServiceBuildStatus.buildStatus?.match(/^success$/gi) &&
        !!(
          (selfServiceBuildStatus.selfServiceAction ||
            deployment.deploymentAction) + ""
        ).match(new RegExp(`^(${UpActions.join("|")})$`, "gi"));
      Object.assign(stateObj, {
        selfServiceBuildStatus: {
          ...selfServiceBuildStatus,
          actionInProgress,
          actionSuccess,
        },
      });
    }
    selfServiceBuildLogs &&
      (stateObj.selfServiceBuildLogs = selfServiceBuildLogs);
    this.setState(stateObj);
  };

  processDeploymentStatus = () => {
    const { ticketDetails } = this.props;
    const { deploymentStatus, deploymentDetails } = ticketDetails;
    const stateObj = {};
    deploymentStatus && (stateObj.deploymentStatus = deploymentStatus);
    deploymentDetails && (stateObj.deploymentDetails = deploymentDetails);
    this.setState(stateObj);
  };

  processTicketObj = () => {
    const { ticketDetails } = this.props;
    const {
      userToken,
      selfServiceBuildStatus,
      selfServiceBuildLogs,
      deploymentStatus,
      deploymentDetails,
      ...ticketObj
    } = ticketDetails;
    const { dnsName, extraDnsName, jsonSchema } = ticketObj;
    const { formData } = jsonSchema;
    this.setState({ dnsName, extraDnsName, formData, ticketObj });
  };

  processTicketDetails = () => {
    this.processAgentToken();
    this.processBuildStatus();
    this.processDeploymentStatus();
    this.processTicketObj();
  };

  teardownTimers = () => {
    clearInterval(AutoDeployment.ticketStatusTimer);
    clearInterval(AutoDeployment.buildStatusTimer);
    clearInterval(AutoDeployment.deploymentStatusTimer);
  };

  setupTimers = () => {
    const {
      onRefreshTicketStatus = () => {},
      onRefreshBuildStatus = () => {},
      onRefreshDeploymentStatus = () => {},
    } = this.props;
    clearInterval(AutoDeployment.ticketStatusTimer);
    AutoDeployment.ticketStatusTimer = setInterval(() => {
      this.isMounted() && onRefreshTicketStatus();
    }, AutoDeployment.ticketStatusInterval);

    clearInterval(AutoDeployment.buildStatusTimer);
    AutoDeployment.buildStatusTimer = setInterval(() => {
      this.isMounted() && onRefreshBuildStatus();
    }, AutoDeployment.buildStatusInterval);

    clearInterval(AutoDeployment.deploymentStatusTimer);
    AutoDeployment.deploymentStatusTimer = setInterval(() => {
      this.isMounted() && onRefreshDeploymentStatus();
    }, AutoDeployment.deploymentStatusInterval);
  };

  applyAction = async (props) => {
    const { ticketDetails } = this.props;
    if (!ticketDetails) {
      return;
    }
    Object.assign(props, {
      onProceed: () => {
        this.setState({ isLoading: true });
      },
      onSuccess: ({ deploymentTicketData }) => {
        this.setState({ isLoading: false });
        if (deploymentTicketData) {
          this.props.history.push(
            `/deployment/${deploymentTicketData.orgTicketId}`,
          );
        }
      },
      onFailure: (error) => {
        this.props.alert.error(error.message);
        this.setState({ isLoading: false });
      },
    });
    const { ticketAction } = props;
    if (ticketAction === cloneAction) {
      await confirmCloneDeploymentAction({
        ticketDetails,
        ...props,
      });
    } else {
      await confirmDeploymentAction({
        ticketDetails,
        ...props,
      });
    }
  };

  onSubscribeAndApply = () => {
    const { ticketDetails } = this.props;
    const { formData } = this.state;
    const solutionItem = this.getSelectedSelfService();
    this.props.history.push("/checkout", {
      ticketDetails: {
        ...ticketDetails,
        jsonSchema: { ...ticketDetails.jsonSchema, formData },
      },
      solutionItem,
    });
  };

  onUpdateAndApply = async () => {
    const { ticketDetails } = this.props;
    const { formData } = this.state;
    const { deployment, jsonSchema } = ticketDetails;
    const { formData: prevFormData } = jsonSchema;
    const { cluster: prevCluster } = prevFormData;
    const {
      dnsName: prevDnsName,
      extraDnsName: prevExtraDnsName,
      capacity: prevCapacity,
    } = prevCluster;
    const { cluster } = formData;
    const { dnsName, extraDnsName, capacity } = cluster;
    const { servicePlans = {} } = this.getSelectedSelfService() || {};
    const planItem = servicePlans[capacity];
    if (!planItem || !planItem.id) {
      this.props.alert.error("Subscription plan is missing");
      return;
    }
    const confirmProps = {
      title: <>Update {ticketDetails.title}?</>,
      message: (
        <div>
          <p>
            Are you sure you want to update the following fields of{" "}
            <strong>{ticketDetails.title}</strong>?
          </p>
          <Table size="sm" bordered>
            <tbody>
              <tr>
                <th>Field Name</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
              {capacity !== prevCapacity ? (
                <tr>
                  <th>Capacity</th>
                  <td>{prevCapacity}</td>
                  <td>{capacity}</td>
                </tr>
              ) : null}
              {dnsName !== prevDnsName ? (
                <tr>
                  <th>Clouve Domain</th>
                  <td>{prevDnsName}</td>
                  <td>{dnsName}</td>
                </tr>
              ) : null}
              {extraDnsName !== prevExtraDnsName ? (
                <tr>
                  <th>Custom Domain</th>
                  <td>{prevExtraDnsName}</td>
                  <td>{extraDnsName}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </div>
      ),
      confirmText: "Proceed",
      confirmColor: "primary",
      cancelText: "Cancel",
      cancelColor: "link",
    };

    const proceed = await confirm(confirmProps);
    if (!proceed) return;

    try {
      this.setState({ isLoading: true });
      const { updateDeployment } = await submitUpdateDeployment({
        planId: planItem.id,
        jsonSchema: { ...jsonSchema, formData },
        deploymentId: deployment.id,
      });
      if (updateDeployment && updateDeployment.code === 200) {
        this.showSuccessMessage(updateDeployment.message);
        if (
          capacity !== prevCapacity ||
          dnsName !== prevDnsName ||
          extraDnsName !== prevExtraDnsName
        ) {
          await this.applyAction({
            ticketAction: updateSubscriptionAction,
            forceConfirm: true,
          });
        }
      } else {
        this.errorHandler(updateDeployment);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  getSelectedSelfService = () => {
    const { selfServiceTypes = [] } = this.props;
    const { ticketObj = {}, deploymentStatus = {} } = this.state;
    const { extraAttr = {} } = ticketObj;
    const { selfServiceType: selectedService } = extraAttr;
    const { attributes } = deploymentStatus;
    const selfServiceItem =
      selfServiceTypes.filter(
        ({ serviceName }) => serviceName === selectedService,
      )[0] || {};
    const { serviceAttributes = {} } = selfServiceItem;
    return {
      ...selfServiceItem,
      serviceAttributes: { ...serviceAttributes, ...attributes },
    };
  };

  onFormDataFocus = async (fieldId, fieldValue) => {
    this.setState({ blurValidationPending: true });
  };

  onFormDataChange = async (formData, errors, fieldId) => {
    this.setState({ formData }, () => {
      if (fieldId && fieldId.match(/_capacity$/gi)) {
        this.onFormDataBlur(fieldId);
      }
    });
  };

  onFormDataBlur = async (fieldId, fieldValue) => {
    const { ticketDetails } = this.props;
    const { formData } = this.state;
    const { jsonSchema } = ticketDetails;
    const { formData: persistedFormData } = jsonSchema;
    const selectedServiceItem = this.getSelectedSelfService();
    const { servicePlans } = selectedServiceItem;
    const extraErrors = await validateClusterFields({
      persistedFormData,
      formData,
      servicePlans,
    });
    this.setState({ extraErrors, blurValidationPending: false });
  };

  handleCapacitySelect = (capacity) => {
    const { formData } = this.state;
    const { cluster = {} } = formData;
    this.setState({
      formData: { ...formData, cluster: { ...cluster, capacity } },
    });
  };

  getUpdatedTicketDetails = () => {
    const { ticketDetails } = this.props;
    const { formData, deploymentStatus } = this.state;
    const { jsonSchema } = ticketDetails;
    const { attributes = {} } = deploymentStatus;
    return {
      ...ticketDetails,
      jsonSchema: {
        ...jsonSchema,
        formData: { ...jsonSchema.formData, ...formData },
      },
      attributes,
    };
  };

  renderDynamicForm = (overrideProps = {}) => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { extraErrors } = this.state;
    const readonly = ticketDetails.state === TICKET_STATES.inProgress;
    const ticketDetailsClone = this.getUpdatedTicketDetails();
    return (
      <DynamicForm
        onFormDataFocus={this.onFormDataFocus}
        onFormDataBlur={this.onFormDataBlur}
        onFormDataChange={this.onFormDataChange}
        ticketDetails={ticketDetailsClone}
        extraErrors={extraErrors}
        disabled={true}
        readonly={readonly || userRoleInOrg.isAgent}
        {...overrideProps}
      />
    );
  };

  renderStatus = () => {
    const { ticketDetails } = this.props;
    const {
      deploymentStatus,
      selfServiceBuildStatus = {},
      isLoading,
    } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    return (
      <DeploymentStatus
        ticketDetails={ticketDetails}
        selfServiceBuildStatus={selfServiceBuildStatus}
        deploymentStatus={deploymentStatus}
        onShowSettingsClick={() => {
          window.location.hash = "/settings";
        }}
      />
    );
  };

  renderCapacity = () => {
    const { ticketDetails } = this.props;
    const readonly = ticketDetails.state === TICKET_STATES.inProgress;

    const { isLoading, formData } = this.state;
    const { cluster = {} } = formData;
    const { capacity: selectedCapacity = "x-small" } = cluster;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    const solutionItem = this.getSelectedSelfService();
    return (
      <div className="p-2">
        {this.renderDynamicForm(CAPACITY_ONLY)}
        <SolutionItem
          ticketDetails={ticketDetails}
          solutionItem={solutionItem}
          selectedCapacity={selectedCapacity}
          onCapacitySelect={this.handleCapacitySelect}
          disabled={readonly}
          hideTrialPeriod={true}
        />
      </div>
    );
  };

  renderDomains = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    return <div className="p-2">{this.renderDynamicForm(DOMAINS_ONLY)}</div>;
  };

  renderCloud = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    return <div className="p-2">{this.renderDynamicForm(CLOUD_ONLY)}</div>;
  };

  renderSupportTicket = () => {
    const { supportTicket } = this.props;
    if (supportTicket) {
      return <div className="m-2">{supportTicket}</div>;
    }
    return null;
  };

  renderSettings = () => {
    const { ticketDetails } = this.props;
    const { isLoading, formData } = this.state;
    const { jsonSchema } = ticketDetails;
    const { formData: persistedFormData } = jsonSchema;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    const solutionItem = this.getSelectedSelfService();
    const { cluster = {} } = formData;
    const { capacity = "x-small" } = cluster;
    return (
      <div className="p-2">
        <Collapsable label="App Configuration">
          {this.renderDynamicForm({
            readonly: true,
          })}
        </Collapsable>
        <Collapsable label="App Capacity">
          <SolutionItem
            selected={persistedFormData?.cluster?.capacity !== capacity}
            solutionItem={solutionItem}
            selectedCapacity={capacity}
            hideTrialPeriod={true}
          />
        </Collapsable>
      </div>
    );
  };

  renderBackups = () => {
    const { ticketDetails } = this.props;
    const { isLoading, deploymentDetails } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    } else {
      return (
        <DeploymentBackups
          ticketDetails={ticketDetails}
          deploymentDetails={deploymentDetails}
          actionButtonsDisabled={
            ticketDetails.state === TICKET_STATES.inProgress
          }
          applyAction={this.applyAction}
        />
      );
    }
  };

  renderLogs = () => {
    const { isLoading, selfServiceBuildLogs } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    } else {
      return <DeploymentLogs deploymentLogs={selfServiceBuildLogs} />;
    }
  };

  renderServiceAgreement() {
    const { ticketDetails } = this.props;
    const { deployment = {} } = ticketDetails;
    const { agreementUrl } = deployment;
    if (agreementUrl) {
      return (
        <div className="text-center">
          <small>
            <a href={agreementUrl} target="_blank" className="text-nowrap">
              Service Agreement
            </a>
          </small>
        </div>
      );
    } else {
      return null;
    }
  }

  renderUserActions = () => {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const {
      isLoading,
      formData,
      blurValidationPending,
      extraErrors,
      selfServiceBuildStatus,
    } = this.state;
    const { jsonSchema } = ticketDetails;
    const { formData: persistedFormData } = jsonSchema;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    const dirty = !_.isEqual(formData, persistedFormData);
    const readonly = ticketDetails.state === TICKET_STATES.inProgress;
    const actionButtonsDisabled =
      readonly ||
      blurValidationPending ||
      userRoleInOrg.isAgent ||
      Object.keys(extraErrors).length > 0;
    return (
      <UserActions
        ticketDetails={ticketDetails}
        formDataDirty={dirty}
        actionButtonsDisabled={actionButtonsDisabled}
        selfServiceBuildStatus={selfServiceBuildStatus}
        applyAction={this.applyAction}
        onSubscribe={this.onSubscribeAndApply}
        onSaveAndApply={this.onUpdateAndApply}
      />
    );
  };

  renderPrivilegedActions = () => {
    const { ticketDetails } = this.props;
    const { userToken, selfServiceBuildStatus } = this.state;
    return (
      <PrivilegedActions
        ticketDetails={ticketDetails}
        userToken={userToken}
        actionButtonsDisabled={ticketDetails.state === TICKET_STATES.inProgress}
        selfServiceBuildStatus={selfServiceBuildStatus}
        applyAction={this.applyAction}
      />
    );
  };

  isVisible = (tabId) => {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    if (tabId.match(/^\/agent/gi)) {
      return userRoleInOrg.isAgent;
    }
    return true;
  };

  renderTabs = () => {
    return (
      <TabNav
        tabs={[
          {
            tabId: "/status",
            tabLabel: "Status",
            tabIcon: "fa fa-server",
            tabComponent: this.renderStatus(),
          },
          {
            tabId: "/cloud",
            tabLabel: "Cloud",
            tabIcon: "fa fa-cloud",
            tabComponent: this.renderCloud(),
          },
          {
            tabId: "/capacity",
            tabLabel: "Capacity",
            tabIcon: "fa fa-hard-drive",
            tabComponent: this.renderCapacity(),
          },
          {
            tabId: "/domains",
            tabLabel: "Domains",
            tabIcon: "fa fa-at",
            tabComponent: this.renderDomains(),
          },
          {
            tabId: "/backups",
            tabLabel: "Backups",
            tabIcon: "fa fa-rotate",
            tabComponent: this.renderBackups(),
          },
          {
            tabId: "/ai-agent",
            tabLabel: "AI Agent",
            tabIcon: "fa fa-microchip",
            tabComponent: this.renderSupportTicket(),
          },
          {
            tabId: "/settings",
            tabLabel: "Settings",
            tabComponent: this.renderSettings(),
            hideLabel: true,
          },
          {
            tabId: "/agent",
            tabLabel: "Agent",
            tabComponents: [
              {
                tabId: "/app-config",
                tabLabel: "App Config",
                tabComponent: this.renderSettings(),
              },
              {
                tabId: "/logs",
                tabLabel: "Logs",
                tabComponent: this.renderLogs(),
              },
            ],
          },
        ]}
        isVisible={this.isVisible}
      />
    );
  };

  render() {
    const { ticketDetails } = this.props;
    const { selfServiceBuildStatus = {}, isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    const { actionInProgress } = selfServiceBuildStatus;
    const { deployment = {} } = ticketDetails;
    const activeSubscription = isSubscriptionStatusAny(deployment, [
      "pending",
      "active",
      "exempt",
    ]);
    return (
      <div className="deployment-detail-view" ref={this.isMountedRef}>
        {!activeSubscription ? (
          <Alert color="warning">
            <p>
              Your application is currently operating under an{" "}
              <strong>inactive subscription</strong>, which limits access to
              essential premium features. By activating your subscription,
              you'll unlock the following benefits:
            </p>
            <ul>
              <li>
                <strong>Custom Domain Names:</strong> Build your brand identity
                with a unique, professional web presence.
              </li>
              <li>
                <strong>Automated Backups:</strong> Protect your data with
                seamless, reliable backups to prevent data loss.
              </li>
              <li>
                <strong>AI Agent Access:</strong> Enhance productivity and user
                experience with our AI-powered agent.
              </li>
              <li>
                <strong>Application Cloning:</strong> Quickly create duplicate
                versions of your application to streamline development and
                testing.
              </li>
            </ul>
            <p>
              Don’t miss out on maximizing your application’s potential.{" "}
              <strong>SUBSCRIBE NOW</strong> to enjoy the full power and
              flexibility of these features!
            </p>
          </Alert>
        ) : null}
        {actionInProgress ? (
          <DeploymentProgress selfServiceBuildStatus={selfServiceBuildStatus} />
        ) : null}
        {this.renderTabs()}
        {this.renderServiceAgreement()}
        {this.renderUserActions()}
        {this.renderPrivilegedActions()}
      </div>
    );
  }
}

export default withToast(withRouter(AutoDeployment));
