import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import Logo from "#Components/Logo.jsx";
import { submitForgotPassword } from "#Graphql/mutate";
import withRouter from "#hoc/withRouter";
import { ForgotPasswordValidator } from "#pages/Account/helpers";
import React, { PureComponent } from "react";

import { withToast } from "#hoc/withToast";
import { Link } from "react-router-dom";

import { Alert, FormGroup, Label } from "reactstrap";

class ForgotPassowrd extends PureComponent {
  state = {
    isLoading: false,
    showSuccessMessage: "",
  };

  errorHandler = (error) => {
    this.props.alert.error(error.message || "Unknown Error");
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false, showSuccessMessage: true });
    this.props.alert.success(message);
  };

  handleForgetPassword = async ({ formFields: values }) => {
    this.setState({ isLoading: true });
    grecaptcha.enterprise.ready(async () => {
      try {
        const captchaToken = await grecaptcha.enterprise.execute(
          VITE_GOOGLE_RECAPTCHA_ID,
          { action: "forgot_password" },
        );
        const { email } = values;
        const { forgotPassword } = await submitForgotPassword({
          captchaToken,
          email,
        });
        if (forgotPassword && forgotPassword.code === 200) {
          this.showSuccessMessage(forgotPassword.message);
        } else {
          this.errorHandler(forgotPassword);
        }
      } catch (err) {
        this.errorHandler(err);
      }
    });
  };

  render() {
    const { isLoading, showSuccessMessage } = this.state;
    const classNames = ["forgot-password", "form"];
    isLoading && classNames.push("spinner");

    if (showSuccessMessage) {
      return (
        <div className={classNames.join(" ")}>
          <FormGroup className="mb-3 text-center">
            <h5>Forgot your password?</h5>
            <Alert color="success" className="m-4">
              A password reset link has been sent to your email.
            </Alert>
            <Link to={"/login"}>Login</Link>
          </FormGroup>
        </div>
      );
    }

    return (
      <div className={classNames.join(" ")}>
        <Logo />
        <h5 className="text-center m-4">Forgot your password?</h5>
        <InputForm
          validator={ForgotPasswordValidator}
          onSubmit={this.handleForgetPassword}
          validateOnBlur
        >
          <FormGroup>
            <Label for="forgotPassEmail">Email</Label>
            <TextField id="forgotPassEmail" type="email" name="email" />
          </FormGroup>
          <FormGroup className="mb-3">
            <SubmitButton size="lg" color="primary" block={true}>
              Retrieve Password
            </SubmitButton>
          </FormGroup>
          <FormGroup className="mb-3 text-center">
            <Link to={"/login"}>Login</Link>
          </FormGroup>
        </InputForm>
      </div>
    );
  }
}

export default withToast(withRouter(ForgotPassowrd));
