export const getServiceSchema = "/thermo/getSchema";

// agent actions
export const agentLaunchAction = "/thermo/action/agent/launch";
export const agentUpgradeAction = "/thermo/action/agent/upgrade";
export const agentDuplicateAction = "/thermo/action/agent/duplicate";
export const agentPauseAction = "/thermo/action/agent/pause";
export const agentResumeAction = "/thermo/action/agent/resume";
export const agentStatusAction = "/thermo/action/agent/status";
export const agentDeleteAction = "/thermo/action/agent/delete";
export const agentTokenAction = "/thermo/action/agent/token";
export const agentBackupAction = "/thermo/action/agent/backup";
export const agentEnableSSOAction = "/thermo/action/agent/enable_sso";
export const agentDisableSSOAction = "/thermo/action/agent/disable_sso";

// user actions
export const cloneAction = "/thermo/action/clone";
export const upgradeAction = "/thermo/action/upgrade";
export const deleteAction = "/thermo/action/delete";
export const pauseAction = "/thermo/action/pause";
export const resumeAction = "/thermo/action/resume";
export const tokenAction = "/thermo/action/token";
export const backupAction = "/thermo/action/backup";
export const cancelTrialAction = "/thermo/action/cancel_trial";
export const cancelSubscriptionAction = "/thermo/action/cancel_subscription";
export const updateSubscriptionAction = "/thermo/action/update_subscription";

// deployment configuration actions
export const UpActions = [
  "clone",
  "launch",
  "update",
  "resume",
  "token",
  "backup",
  "status",
  "upgrade",
  "agent/token",
  "agent/clone",
  "agent/enable_sso",
  "agent/disable_sso",
];
