import { SolutionItem } from "#Components/SolutionItem";
import {
  submitCreateDeployment,
  submitSubscribeDeployment,
} from "#Graphql/mutate";
import { fetchClientPaymentMethod, getClientToken } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import { withToast } from "#hoc/withToast";
import dropin from "braintree-web-drop-in";
import _ from "lodash";
import React, { PureComponent, createRef } from "react";
import { Alert, Button, Card, Col, Row, Spinner } from "reactstrap";

class Checkout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      solutionItem: {},
      capacity: "x-small",
      planItem: null,
      clientPaymentToken: null,
      isLoading: true,
      currentPlanEndDate: "",
      agreementChecked: false,
      payButtonEnabled: false,
      ticketDetails: null,
      dropInInstance: null,
    };
    this.dropinContainer = createRef();
    this.instance = null;
  }

  async componentDidMount() {
    const { history, location = {} } = this.props;
    const { ticketDetails, solutionItem } = location.state || {};
    if (!ticketDetails || !solutionItem) {
      history.push("/");
      return;
    }
    const { servicePlans = {} } = solutionItem;
    const { jsonSchema } = ticketDetails;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;
    const planItem = servicePlans[capacity];
    try {
      const { clientToken } = await getClientToken();
      if (clientToken) {
        this.setState({
          clientToken,
          isLoading: true,
          ticketDetails,
          solutionItem,
          planItem,
        });
      } else {
        this.errorHandler({ message: "Failed to get client token" });
      }
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { clientToken, clientPaymentToken, dropInInstance } = this.state;
    const { ticketDetails, solutionItem } = this.state;
    const { location: prevLocation = {} } = prevProps;
    const { ticketDetails: prevTicketDetails, solutionItem: prevSolutionItem } =
      prevLocation.state || {};

    // Handle client token update
    if (clientToken !== prevState.clientToken) {
      this.setState({ isLoading: true });
      const { getClientPaymentMethod: clientPaymentToken } =
        await fetchClientPaymentMethod();
      this.setState({ isLoading: false });
      if (clientPaymentToken) {
        this.setState({ clientPaymentToken });
      } else {
        this.errorHandler({ message: "Failed to get client payment token" });
      }
    }

    // Handle ticket details or solution item update
    if (
      ticketDetails !== prevTicketDetails ||
      solutionItem !== prevSolutionItem
    ) {
      const { servicePlans = {} } = solutionItem;
      const { jsonSchema } = ticketDetails;
      const { formData } = jsonSchema;
      const { cluster } = formData;
      const { capacity } = cluster;
      const planItem = servicePlans[capacity];
      this.setState({ ticketDetails, solutionItem, planItem });
    }

    // Initialize Braintree Drop-in when token is received and instance doesn't exist
    if (
      clientPaymentToken &&
      !dropInInstance &&
      (!prevState.clientPaymentToken ||
        prevState.clientPaymentToken !== clientPaymentToken)
    ) {
      await this.initializeBraintreeDropin();
    }
  }

  componentWillUnmount() {
    // Clean up the Drop-in instance when component unmounts
    if (this.state.dropInInstance) {
      this.state.dropInInstance.teardown();
    }
  }

  initializeBraintreeDropin = async () => {
    try {
      const options = {
        authorization: this.state.clientPaymentToken,
        container: this.dropinContainer.current,
        paymentOptionPriority: ["card", "paypal"],
        paypal: { flow: "vault", amount: "", currency: "USD" },
        defaultFirst: true,
        cardPaymentMethodPayload: { details: "cardType" },
      };

      const instance = await dropin.create(options);

      // Set up event listeners
      instance.on("paymentMethodRequestable", this.onPaymentMethodRequestable);
      instance.on(
        "noPaymentMethodRequestable",
        this.onNoPaymentMethodRequestable,
      );
      instance.on("paymentOptionSelected", this.onPaymentOptionSelected);

      this.setState({ dropInInstance: instance });
      this.instance = instance;

      if (instance.isPaymentMethodRequestable()) {
        this.setState({ payButtonEnabled: true });
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  checkoutSubmit = async () => {
    const { ticketDetails, planItem, dropInInstance } = this.state;
    if (!ticketDetails || !dropInInstance) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const { nonce: paymentMethodNonce } =
        await dropInInstance.requestPaymentMethod();

      if (ticketDetails.deployment) {
        const { subscribeDeployment } = await submitSubscribeDeployment({
          paymentMethodNonce,
          planId: planItem.id,
          jsonSchema: ticketDetails.jsonSchema,
          deploymentId: ticketDetails.deployment.id,
        });
        if (subscribeDeployment && subscribeDeployment.code === 200) {
          this.showSuccessMessage(subscribeDeployment.message);
          if (subscribeDeployment.deploymentTicketData) {
            this.deploymentCheckoutSuccess(
              subscribeDeployment.deploymentTicketData,
            );
          }
        } else {
          this.errorHandler(subscribeDeployment);
        }
      } else {
        const { createDeployment } = await submitCreateDeployment({
          paymentMethodNonce,
          deploymentData: ticketDetails,
        });
        if (createDeployment && createDeployment.code === 200) {
          const { message, deploymentTicketData } = createDeployment;
          this.showSuccessMessage(message);
          if (deploymentTicketData) {
            this.props.history.push(
              `/deployment/${deploymentTicketData.orgTicketId}`,
            );
          }
        } else {
          this.errorHandler(createDeployment);
        }
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  onNoPaymentMethodRequestable = () => {
    this.setState({ payButtonEnabled: false });
  };

  onPaymentMethodRequestable = (payload) => {
    const { dropInInstance } = this.state;
    if (dropInInstance && dropInInstance.isPaymentMethodRequestable()) {
      this.setState({ payButtonEnabled: true });
    }
  };

  onPaymentOptionSelected = (payload) => {
    const { dropInInstance } = this.state;
    if (dropInInstance && dropInInstance.isPaymentMethodRequestable()) {
      this.setState({ payButtonEnabled: true });
    }
  };

  toggleAgreementCheckbox = (agreementChecked) => {
    this.setState({ agreementChecked });
  };

  deploymentCheckoutSuccess = (deploymentTicketData) => {
    const { history } = this.props;
    history.push(`/checkout/success#/${deploymentTicketData.orgTicketId}`);
  };

  handleCapacitySelect = (capacity) => {
    const { ticketDetails } = this.state;
    if (ticketDetails) {
      _.set(
        ticketDetails,
        "jsonSchema.formData.cluster.capacity",
        capacity || "x-small",
      );
      this.setState({ ticketDetails: { ...ticketDetails } });
    }
  };

  renderPaymentForm() {
    const { ticketDetails } = this.state;
    if (!ticketDetails) {
      return;
    }

    const { isLoading } = this.state;

    return (
      <div className="checkout-cart p-3">
        {isLoading && (
          <div className="loading-spinner">
            <Spinner type="grow" />
          </div>
        )}

        {/* Container for Braintree Drop-in UI */}
        <div ref={this.dropinContainer}></div>

        <Alert color="warning text-center mt-4">
          You can cancel subscription at any time to stop the monthly payments.
        </Alert>
      </div>
    );
  }

  onCancel = () => {
    const { ticketDetails } = this.state;
    if (ticketDetails.orgTicketId) {
      this.props.history.push(`/deployment/${ticketDetails.orgTicketId}`);
    } else {
      this.props.history.push("/deployments");
    }
  };

  render() {
    const {
      ticketDetails,
      solutionItem,
      agreementChecked,
      payButtonEnabled,
      isLoading,
      clientPaymentToken,
    } = this.state;
    if (!ticketDetails) {
      return;
    }
    const { jsonSchema } = ticketDetails;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;
    return (
      <Card className="content-wrapper create-ticket-wrapper pb-5">
        <div className="payment-checkout">
          <Row>
            <Col>
              <h5 className="pt-4 pb-4">Cart</h5>
              <SolutionItem
                solutionItem={solutionItem}
                selectedCapacity={capacity}
                onCapacitySelect={this.handleCapacitySelect}
                selected
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="pt-4 pb-4">Payment Method</h5>
              {clientPaymentToken ? (
                this.renderPaymentForm()
              ) : (
                <div className="loading-spinner">
                  <Spinner type="grow" />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={isLoading ? "invisible" : ""}>
              <p className="agreement-checkbox">
                <input
                  type="checkbox"
                  checked={agreementChecked}
                  onChange={(evt) =>
                    this.toggleAgreementCheckbox(evt.target.checked)
                  }
                />
                <label
                  onClick={() =>
                    this.toggleAgreementCheckbox(!agreementChecked)
                  }
                >
                  I have read and agree to the
                </label>
                <a
                  href={`/thermo/agreement`}
                  target="_blank"
                  className="text-nowrap"
                >
                  Service Agreement
                </a>
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col sm={6}>
              <Button
                block
                id="submitButton"
                color="primary"
                onClick={this.checkoutSubmit}
                disabled={!agreementChecked || !payButtonEnabled}
              >
                {ticketDetails?.deployment
                  ? "SUBSCRIBE"
                  : "SUBSCRIBE & INSTALL"}
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pb-5">
            <Col sm={3}>
              <Button
                block
                outline
                size="sm"
                color="danger"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

export default withToast(withRouter(Checkout));
